<template>
	<div class="dashboard-main">
		<button type="button" class="mb-4 twn-button float-right" @click="ExportReport" v-bind:disabled="loading">
			<span v-if="!loading">
				Exporter le rapport
			</span>
			<span v-else>
				Chargement...
			</span>
		</button>
	</div>
</template>

<script>
	import trackingReport from '@/utils/trackingReport'

	export default {
		name: 'MainDashboard',
		data() {
			return {
				loading: false
			}
		},
		methods: {
			async ExportReport(){
				this.loading = true
				
				try {
					await trackingReport.export(this.$store)
				} catch (err) {
					console.error(err)
				}

				this.loading = false
			}
		}
	}
</script>

<style lang="scss" scoped></style>