<template>
  <div class="item-chooser-control flex" :class="{ 'highlighted': highlighted }">
    <!-- Label -->
    <span v-if="label" class="item-label">{{label}}</span>

    <!-- Button -->
    <button 
    v-if="!item"
    class="twn-button text-xs"
    type="button"
    @pointerdown.stop="selectItem"
    @drop.prevent.stop="dropItem"
    >Choisir</button>

    <!-- Preview -->
    <div v-else
    class="item-preview cursor-pointer"
    @drop.prevent.stop="dropItem">
      <span @pointerdown.stop="selectItem">{{item.name}}</span>
      <span class="button-edit"
      @pointerdown.stop="selectItem">
        <img src="@/assets/images/EDIT.svg">
      </span>
      <span v-if="clearable" class="button-clear"
      @pointerdown.stop="clearItem">
        <img src="@/assets/images/DELETE.svg">
      </span>
    </div>
  </div>
</template>


<script>
  import store from "../../store"

  export default {
    props: {
      ikey: String,
      label: String,
      data_source: Object,
      clearable: Boolean,
      getData: Function,
      putData: Function,
    },
    data() {
      return {
        item: null,
        highlighted: false,
      }
    },
    watch: {
      item(val, oldVal) {
        if (val == oldVal)
          return

        // Use custom event to make them available outside of the current Vue.js context
        this.$el.dispatchEvent(new CustomEvent('content-update', { bubbles: true, composed: true }))
      }
    },
    methods: {
      selectItem() {
        // Use custom event to make them available outside of the current Vue.js context
        this.$el.dispatchEvent(new CustomEvent('select-item', { bubbles: true, composed: true, detail: this.$el }))
        this.$el.dispatchEvent(new CustomEvent('select-item-' + this.ikey, { bubbles: true, composed: true }))
      },
      dropItem(e) {
        const itemJSON = (e.dataTransfer ? e.dataTransfer.getData("application/json") : e.detail)

        if (itemJSON) {
          const item = (itemJSON ? JSON.parse(itemJSON) : null)       

          if (item) {
            this.item = item

            if (this.ikey)
              this.putData(this.ikey, this.item.id)
          }

          // Use custom event to make them available outside of the current Vue.js context
          this.$el.dispatchEvent(new CustomEvent('item-selected', { bubbles: true, composed: true }))
        }
      },
      clearItem() {
        this.item = null

        if (this.ikey)
          this.putData(this.ikey, null)
      },
      setSelected(e) {
        if (e.detail !== false && e.detail !== true)
          return

        this.highlighted = e.detail
      },
    },
    mounted() {
      const id = this.getData(this.ikey)

      if (id && this.data_source.store) {
        let list = []

        // Get data source from the vuex store state
        if (this.data_source.store) {
          // With or without a sub module
          if (this.data_source.store.module) {
            list = store.state[this.data_source.store.module][this.data_source.store.state]
          } else {
            list = store.state[this.data_source.store.state]
          }
        }

        if (list && list.length > 0) {
          this.item = list.find((item) => (item.id == id))
        }
      }

      this.$el.addEventListener('set-selected', this.setSelected)
      this.$el.addEventListener('set-item', this.dropItem)
    },
    beforeDestroy() {
      this.$el.removeEventListener('set-selected', this.setSelected)
      this.$el.removeEventListener('set-item', this.dropItem)
    },
  }
</script>


<style lang="scss">
.item-chooser-control {
  .twn-button[type="button"] {
    min-width: auto;
    padding: 0.4rem 1.2rem;
  }
}

.item-label {
  color: $textLight;
  min-width: 50px;
  line-height: 34px;
  margin-right: 4px;
  vertical-align: middle;
}

.item-preview {
  @apply text-principal-gray;
  line-height: 34px;

  .button-edit, .button-clear {
    img {
      display: inline-block;
      margin-left: 8px;
      height: 16px;
    }
  }
}

.highlighted {
  .twn-button[type="button"], .item-preview, .item-preview-image img {
    @apply text-red border-red;
  }
}
</style>
