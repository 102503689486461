import gql from 'graphql-tag'

const GC_GET_ASSET_BY_ID = gql`
query GetAssetByID($id: uuid!) {
  media(where: {id: {_eq: $id}}) {
    id
    title
    name
    path
    thumbnail_path
    mediaType {
      name
    }
    created: contentLogs(where: {logType: {slug: {_eq: "content_create"}}}) {
      user {
        first_name
        name
      }
      created_at
    }
    updated: contentLogs(order_by: {created_at: desc}) {
      user {
        first_name
        name
      }
      created_at
    }
  }
  node(where: {node_content: {_eq: $id}}) {
    id
    scenario {
      id
      name
      title
    }
  }
  question:game(where: {questions: {media_id: {_eq: $id}}}) {
    id
    name
    title
  }
  game(where: {medias: {media_id: {_eq: $id}}}) {
    id
    name
    title
  }
}
`

const GC_GET_ASSET_LIST = gql`
query GetMediaList {
    media(order_by: {created_at: desc}) {
      id
      name
      path
      title
      description
      thumbnail_path
      type:mediaType{
        name
        id
        slug
      }
    }
  }
`

const GC_GET_TYPE_LIST = gql`
query GetTypes {
  media_type(order_by: {name: asc}) {
    name
    id
    slug
  }
}
`

const GC_INSERT_MEDIA_ONE = gql`
mutation InsertMedia($name:String!, $path:String!, $tPath:String!, $type:uuid!) {
  insert_media_one(object: {name: $name, path: $path, thumbnail_path: $tPath, media_type_id:$type}) {
    id
    path
    name
  }
}`

const GC_UPDATE_MEDIA = gql`
mutation UpdateMedia($id: uuid!, $name: String, $title:String, $description:String, $type:uuid, $path: String, $thumbnail_path: String) {
  update_media(where: {id: {_eq: $id}}, _set: {description: $description, name: $name, title: $title, media_type_id: $type, thumbnail_path: $thumbnail_path, path:$path}) {
    affected_rows
  }
}
`

const GC_DELETE_MEDIA_ONE = gql`
mutation DeleteMedia($id:uuid!) {
  delete_media(where: {id: {_eq: $id}}) {
    affected_rows
  }
}

`

export {
    GC_GET_ASSET_LIST,
    GC_GET_TYPE_LIST,
    GC_UPDATE_MEDIA,
    GC_INSERT_MEDIA_ONE,
    GC_DELETE_MEDIA_ONE,
    GC_GET_ASSET_BY_ID
}