import axios from 'axios'

import apollo from '@/apolloClient'

import {
    GC_GET_USER_LIST,
    GC_GET_USER_BY_ID,
    GC_ADD_USER_ONE,
    GC_UPDATE_USER_BY_ID,
    GC_UPDATE_USER_EMAIL_BY_ID,
    GC_DELETE_USER_BY_ID,
} from '@/graphql/user'

import browserEventBus from '@/utils/browserEventBus'

const authURL = process.env.VUE_APP_API_URL + '/auth'

export default {
    namespaced: true,
    state: {
        list: [],
        items: {},
    },
    mutations: {
        SET_USER_LIST(state, users) {
            state.list = (users || []);
        },
        SET_USER(state, user) {
            if (!user || !user.id)
                return

            state.items[user.id] = user
        },
    },
    actions: {
        // Return default user data object
        getDefault() {
            return {
                name: '',
                first_name: '',
                email: '',
                role: 'user',
                gender: '',
                age: '',
                is_disabled_worker: false,
                level_studies: '',
                actual_position: '',
                actual_working_time: '',
                rise_working_time: false,
                contract: '',
                experiences: '',
                has_professional_courses: false,
                professional_courses: '',
                has_distance_learning: false,
                expectations: '',
                additionnal_needs: ''
            }
        },
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_USER_LIST })

            commit('SET_USER_LIST', response.data.users)
        },
        async getByID({ commit }, id) {
            let response = await apollo.query({
                query: GC_GET_USER_BY_ID,
                variables: { id }
            })

            if (!response.data.users_by_pk) {
                return
            }

            commit('SET_USER', response.data.users_by_pk)
        },
        async save(context, data) {
            let response = null
            let result = {}

            // Update or add the user
            if (data.user.id) {
                // Remove email for update
                const newEmail = data.user.email
                delete data.user.email

                // Update remote data
                response = await apollo.mutate({
                    mutation: GC_UPDATE_USER_BY_ID,
                    variables: data.user
                })

                // Handle adding an email only when the user doesn't have one
                if (newEmail && response.data && response.data.update_users_by_pk && !response.data.update_users_by_pk.email) {
                    await apollo.mutate({
                        mutation: GC_UPDATE_USER_EMAIL_BY_ID,
                        variables: { id: data.user.id, email: newEmail }
                    })
                }

                // Emit browser event
                browserEventBus.emitUpdate(browserEventBus.eventCategory.user, { id: data.user.id })

                result.success = true
            } else {
                try {
                    // Add user to auth api
                    response = await axios.post(
                        authURL + '/adminregister',
                        {
                            email: data.user.email,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + context.rootGetters['Auth/userToken']
                            }
                        }
                    )
                } catch (error) {
                    return {
                        error
                    }
                }
                
                data.user.cognito_id = response.data.message

                // Add remote data
                response = await apollo.mutate({
                    mutation: GC_ADD_USER_ONE,
                    variables: data.user
                })

                result.id = response.data.insert_users_one.id

                // Emit browser event
                browserEventBus.emitAdd(browserEventBus.eventCategory.user, { id: result.id })
            }

            // Refresh cached list
            await context.dispatch('Utils/getUsers', null, { root: true })

            return result
        },
        async delete(context, user) {
            if (!user.id)
                return {
                    error: true
                }

            let response = null

            try {
                response = await apollo.mutate({
                    mutation: GC_DELETE_USER_BY_ID,
                    variables: { id: user.id }
                })

                if (user.cognito_id) {
                    // Delete user from auth api
                    response = await axios.post(
                        authURL + '/admindelete',
                        {
                            username: user.cognito_id
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + context.rootGetters['Auth/userToken']
                            }
                        }
                    )
                }
            } catch (error) {
                return {
                    error
                }
            }

            // Refresh cached list
            await context.dispatch('Utils/getUsers', null, { root: true })

            // Emit browser event
            browserEventBus.emitDelete(browserEventBus.eventCategory.user, { id: user.id })

            return response.data.delete_users_by_pk
        },
    }
}