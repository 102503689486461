<template>
    <div>
        <cooc-list 
            listing="users" 
            :fields="fields" 
            :extraFields="extraFields" 
            :filters="filters" 
            searchText="Rechercher un utilisateur"
            :with-delete-button="false"
            modify-button-text="Éditer la fiche Utilisateur"
        >
            <template v-slot:buttons>
                <div class="md:flex flex-row justify-around hidden user-buttons w-1/3">
                    <button @click="addUser" type="button" class="hidden md:block twn-button whitespace-no-wrap overflow-hidden text-xs mr-2">Ajouter un utilisateur</button>
                    <!-- <button @click="importUserList" type="button" class="twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs">Importer des utilisateurs</button> -->
                </div>
            </template>
            <template v-slot:fab>
                <vue-fab
                    class="md:hidden"
                    fabItemAnimate="alive"
                    fabAliveAnimateBezier="ease-in-out"
                    mainBtnColor="#fff"
                    size="big"
                >
                    <fab-item class="twn-fab import" @clickItem="importUserList" color="#888888" :idx="2" title="Importer des utilisateurs" icon="add" />
                    <fab-item class="twn-fab add" @clickItem="addUser" color="#DC9799" :idx="0.5" title="Ajouter un utilisateur" icon="add" />
                </vue-fab>
            </template>
        </cooc-list>
    </div>
</template>
<script>

import { mapActions } from 'vuex';
import List from '@/components/List'
export default {
    name: 'UserList',
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    label: 'Nom',
                    sortable: true
                },
                {
                    key: 'first_name',
                    label: 'Prénom',
                    sortable: true,
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true,
                },
                {
                    key: 'promotions',
                    db: `promotions:promotion_users{
                        promotion {
                            title
                        }
                    }`,
                    nested: 'title',
                    label: 'Promotion(s)',
                    sortable: true,
                    formatter: (value) => {
                        return Array.isArray(value) ? value.join(' | ') : value
                    }
                },
                {
                    key: 'role',
                    sortable: true,
                    formatter: (value) => {
                        switch (value){
                            case 'user':
                            return "Utilisateur"
                            case 'organisationadmin':
                            return "OfficeAdmin"
                            case 'superadmin':
                            return "SuperAdmin"
                        }
                    }
                },
                {
                    label: 'Statut',
                    key: 'cognito_id',
                    sortable: true,
                    formatter: (value, key, user) => {
                        if (value) {
                            if (user.last_log.length <= 0) {
                                return 'Email envoyé'
                            }

                            return 'Validé'
                        }

                        return 'Inactif'
                    }
                }
            ],
            extraFields: [
                {
                    key: 'last_log',
                    db: `last_log: logs(limit: 1) {
                        id
                    }`
                }
            ],
            filters: []
        }
    },
    props:{
        previousPage: {
            type: Number,
            required: false,
            default: null
        }
    },
    components: {'cooc-list':List},
    methods:{
        ...mapActions('EditItem', ['clearCurrentID']),
        addUser(){
            this.clearCurrentID();
            this.$router.push({name:'users-create'});
        },
        importUserList(){
            // console.log("IMPORT USER LIST");
        }
    }
}
</script>
<style lang="scss">
// .user-buttons{
//     flex-basis: 33%;
// }
</style>