<template>
  <div>
    <List
      listing="conversation"
      :fields="fields"
      order-by="created_at: desc"
      search-text="Rechercher une conversation"
      :pageSize="pageSize"
      edit-route-name="conversation-edit"
    />
  </div>
</template>

<script>
import List from "@/components/List";

export default {
  components: {
    List,
  },
  props: {
    pageSize:{
      type: Number,
      default: 30,
      required: false
    }
  },
  data: () => ({
    fields: [
      {
        key: 'promotion_title',
        db: `promotion_title:promotion{
          title
        }`,
        nested: 'title',
        label: 'Promotion',
        sortable: true,
      },
      {
        key: 'last_message',
        db: `last_message:messages(order_by: {created_at: desc}, limit: 1){
          created_at
        }`,
        nested: 'created_at',
        label: 'Dernier message',
        sortable: true,
        formatter: (value) => {
          const date = new Date(value)

          return !isNaN(date) ? date.toLocaleString() : ""
        }
      },
    ],
  }),
};
</script>