import Vue from 'vue'
import VueRouter from 'vue-router'
import Authentication from '@/views/Authentication';
import Dashboard from '@/views/Dashboard'
import UserList from '@/views/UserList'
import OrganizationList from '@/views/OrganizationList'
import NotFound from '@/views/NotFound'
import UserDetail from '@/views/UserDetail'
import OrganizationDetail from '@/views/OrganizationDetail'
import ScenarioList from '@/views/ScenarioList'
import ScenarioDetail from '@/views/ScenarioDetail'
import MediaList from '@/views/Medias'
import GamesList from '@/views/MinigamesList'
import GameCreation from '@/views/GameCreation'
import PromotionList from '@/views/Promotions/PromotionList'
import PromotionDetail from '@/views/Promotions/PromotionDetail'
import PathList from '@/views/Paths/PathList'
import PathDetails from '@/views/Paths/PathDetails'
import CourseDetail from '@/views/Courses/CourseDetail'
import CourseList from '@/views/Courses/CourseList'
import EntityList from '@/views/EntityList'
import EntityDetail from '@/views/EntityDetail'
import ConversationList from '@/views/Conversation/ConversationList'
import ConversationDetail from "@/views/Conversation/ConversationDetail";

import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: {
      title: "Tableau de bord",
      breadcrumb: "Tableau de bord",
    },
  },
  {
    path: "/auth",
    name: "auth",
    component: Authentication,
    meta: {
      title: "Authentication",
      layout: "no-sidebar",
      noAuth: true,
    },
  },
  {
    path: "/user/list",
    name: "users-list",
    props: true,
    component: UserList,
    meta: {
      breadcrumb: "Utilisateurs",
      title: "Liste des utilisateurs",
    },
  },
  {
    path: "/user/edit/:itemID?",
    name: "users-edit",
    component: UserDetail,
    props: true,
    meta: {
      breadcrumb: "Utilisateurs",
      title: "Editer un utilisateur",
    },
  },
  {
    path: "/user/create",
    name: "users-create",
    component: UserDetail,
    props: true,
    meta: {
      breadcrumb: "Utilisateurs",
      title: "Ajouter un utilisateur",
    },
  },
  {
    path: "/promotion/list",
    name: "promotions-list",
    component: PromotionList,
    meta: {
      breadcrumb: "Promotions",
      title: "Liste des promotions",
    },
  },
  {
    path: "/promotion/edit/:itemID?",
    name: "promotions-edit",
    component: PromotionDetail,
    props: true,
    meta: {
      breadcrumb: "Promotions",
      title: "Éditer une promotion",
    },
  },
  {
    path: "/promotion/create",
    name: "promotions-create",
    component: PromotionDetail,
    props: true,
    meta: {
      breadcrumb: "Ajouter une promotion",
      title: "Ajouter une promotion",
    },
  },
  {
    path: "/course/create",
    name: "course-create",
    component: CourseDetail,
    props: true,
    meta: {
      breadcrumb: "Ajouter un parcours",
      title: "Ajouter un parcours",
    },
  },
  {
    path: "/course/edit/:itemID?",
    name: "course-edit",
    component: CourseDetail,
    props: true,
    meta: {
      breadcrumb: "Editer un parcours",
      title: "Editer un parcours",
    },
  },
  {
    path: "/course/list",
    name: "course-list",
    component: CourseList,
    props: true,
    meta: {
      breadcrumb: "Liste des parcours",
      title: "Liste des parcours",
    },
  },
  {
    path: "/path/list",
    name: "paths-list",
    component: PathList,
    meta: {
      breadcrumb: "Parcours",
      title: "Liste des parcours",
    },
  },
  {
    path: "/path/edit",
    name: "paths-edit",
    component: PathDetails,
    props: true,
    meta: {
      breadcrumb: "Parcours",
      title: "Éditer un parcours",
    },
  },
  {
    path: "/path/create",
    name: "paths-create",
    component: PathDetails,
    props: true,
    meta: {
      breadcrumb: "Parcours",
      title: "Créer un parcours",
    },
  },
  {
    path: "/organisation/list/",
    name: "organisations-list",
    props: true,
    component: OrganizationList,
    meta: {
      breadcrumb: "Gestion des offices",
      title: "Liste des offices",
    },
  },
  {
    path: "/organisation/edit",
    name: "organisations-edit",
    component: OrganizationDetail,
    props: true,
    meta: {
      breadcrumb: "Offices",
      title: "Editer un office",
    },
  },
  {
    path: "/organisation/create",
    name: "organisations-create",
    component: OrganizationDetail,
    props: true,
    meta: {
      breadcrumb: "Offices",
      title: "Ajouter un office",
    },
  },
  {
    path: "/scenario/list",
    name: "scenario-list",
    props: true,
    component: ScenarioList,
    meta: {
      breadcrumb: "Scénarios",
      title: "Liste des scénarios",
    },
  },
  {
    path: "/scenario/edit/:itemID?",
    name: "scenario-edit",
    component: ScenarioDetail,
    props: true,
    meta: {
      title: "Editer un scénario",
      noMargin: true,
    },
  },
  {
    path: "/media/list",
    name: "media-list",
    component: MediaList,
    meta: {
      breadcrumb: "Medias",
      title: "Liste des assets",
    },
  },
  {
    path: "/games/list",
    name: "games-list",
    props: true,
    component: GamesList,
    meta: {
      breadcrumb: "Minijeux",
      title: "Liste des minijeux",
    },
  },
  {
    path: "/game/edit/:gameTypeSlug/:itemID?",
    name: "game-edit",
    component: GameCreation,
    props: true,
    meta: {
      breadcrumb: "Minijeux",
      titleFn: (route, store) => {
        if (route && store) {
          let type = store.state.Games.type_list.find((type) => {
            return type.slug == route.params.gameTypeSlug;
          });
          if (type && !route.params.itemID) {
            return `Créer un ${type.name ? type.name : ""}`;
          } else if (type && route.params.itemID) {
            return `Modifier le ${type.name ? type.name : ""}`;
          }
        }
        return "Minijeu";
      },
    },
  },
  {
    path: "/entity/list",
    name: "entity-list",
    component: EntityList,
    meta: {
      breadcrumb: "Entities",
      title: "Liste des entités",
    },
  },
  {
    path: "/entity/edit/:itemID?",
    name: "entity-edit",
    component: EntityDetail,
    props: true,
    meta: {
      title: "Editer une entité",
    },
  },
  {
    path: "/conversation/list",
    name: "conversation-list",
    component: ConversationList,
    meta: {
      breadcrumb: "Conversations",
      title: "Liste des conversations",
    },
  },
  {
    path: "/conversation/edit/:itemID?",
    name: "conversation-edit",
    component: ConversationDetail,
    props: true,
    meta: {
      breadcrumb: "Conversations",
      title: "Conversation",
    },
  },
  {
    path: "*",
    component: NotFound,
    name: "notfound",
    meta: {
      breadcrumb: "404",
      layout: "no-sidebar",
      title: "404 - Not Found",
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/backoffice/',
  routes
})

// Authentication check
router.beforeEach(async (to, from, next) => {
  // Check if we need to check authentication status
  if (to.meta.noAuth) {
    return next()
  }

  // Check the authentication status of the user
  if (!store.getters['Auth/isLoggedIn']) {
    return next({ name: 'auth' })
  }

  // Handle first page load
  if (!store.state.Auth.userInfo) {
    try {
      await store.dispatch('Auth/getCurrentUserInfo')
    } catch (error) {
      return next(new Error('Failed to load user info'))
    }

    // Check if we didn't get userInfo (can still be null if token has expired)
    if (!store.state.Auth.userInfo) {
      return next({ name: 'auth' })
    }
  }

  return next()
})

export default router
