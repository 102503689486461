<template>
  <!-- Empty/Loading states -->
  <p v-if="!course" class="mt-4 text-center">Aucun parcours sélectionné</p>
  <p v-else-if="!course.id" class="mt-4 text-center">Chargement du parcours...</p>
  
  <!-- Content -->
  <div v-else class="mt-4 border rounded p-4">
    <!-- Header -->
    <div class="flex mb-4 font-principal-medium">
      <p class="w-3/12 text-center">Stagiaire</p>

      <template v-if="promotionType && promotionType.slug == 'collective_promotion'">
        <p class="w-1/12 text-center">Date</p>
        <p class="w-2/12 text-center">Disponible jusqu'au</p>
      </template>
      <template v-else>
        <p class="w-1/12 text-center">Du</p>
        <p class="w-2/12 text-center">Au</p>
      </template>

      <p class="w-4/12 text-center">Séquence</p>
      <p class="w-2/12 text-center">Statut</p>
      <div class="w-8"></div>
    </div>

    <!-- List -->
    <div
    class="flex mb-4"
    v-for="internSequence in internSequenceList"
    :key="internSequence.id + internSequence.userID + internSequence.starDate + internSequence.endDate"
    >
      <p class="w-3/12 text-center" v-if="promotionInternsInfo && promotionInternsInfo[internSequence.userID]">
        {{promotionInternsInfo[internSequence.userID].name}}
      </p>
      <p class="w-1/12 text-center">{{formatDate(internSequence.startDate)}}</p>
      <p class="w-2/12 text-center">{{formatDate(internSequence.endDate)}}</p>
      <p class="w-4/12 text-center" v-if="sequenceInfo && sequenceInfo[internSequence.id]">
        {{sequenceInfo[internSequence.id].name}}
      </p>
      <p class="w-2/12 text-center">A venir</p>
      <div
        class="flex justify-center text-xl font-bold items-center cursor-pointer col-span-1"
        @click="onRemoveInternSequence(internSequence)"
      >
        <div class="border border-gray-light rounded-full text-center w-8 h-8">-</div>
      </div>
    </div>

    <div class="rounded border border-gray-light p-6 mt-12">
      <div class="flex mb-4">
        <div class="flex flex-1 items-center mr-12">
          <label class="mb-0 mr-4 w-1/3">Séquence</label>
          <v-select
          class="w-full"
          :options="sequenceList"
          :clearable="false"
          :filterable="true"
          label="name"
          placeholder="Séquence"
          v-model="sequence"
          />
        </div>

        <div class="flex flex-1 items-center">
          <label class="mb-0 mr-4 w-1/3">Stagiaire</label>
          <v-select
          class="w-full"
          :options="promotionInterns"
          :clearable="false"
          :filterable="true"
          label="name"
          placeholder="Stagiaire"
          v-model="intern"
          />
        </div>
      </div>

      <div class="flex mb-4">
        <div class="flex flex-1 items-center mr-12">
          <label class="mb-0 mr-4 w-1/3">
            {{promotionType && promotionType.slug == 'collective_promotion' ? 'Date' : 'Du'}}
          </label>

          <b-form-datepicker
          :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'long' }"
          v-model="startDate"
          ></b-form-datepicker>
        </div>

        <div class="flex flex-1 items-center">
          <label class="mb-0 mr-4 w-1/3">
            {{promotionType && promotionType.slug == 'collective_promotion' ? 'Disponible jusqu\'au' : 'Au'}}
          </label>

          <b-form-datepicker
          :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'long' }"
          v-model="endDate"
          ></b-form-datepicker>
        </div>
      </div>
      <div class="text-right">
        <button type="button" class="twn-button text-xs" @click="onAddInternSequence">
          Ajouter
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment'

import { mapState } from 'vuex'

export default {
  props: {
    course: {
      type: Object,
    },
    promotion: {
      type: Object,
      required: true,
    },
    promotionType: {
      type: Object,
    },
  },
  data() {
    return {
      sequence: null,
      intern: null,
      startDate: new Date(),
      endDate: new Date(),
    }
  },
  computed: {
    ...mapState('Utils', ['userList']),
    internSequenceList() {
      if (!this.promotion || !this.promotion.sequences)
        return []

      return this.promotion.sequences.filter((sequence) => {
        return (sequence.userID != null)
      })
    },
    promotionInterns() {
      if (!this.userList)
        return []

      return this.userList.filter((user) => {
        return this.promotion.users.some((intern) => (user.id == intern.id))
      }).map((user) => {
        return {
          id: user.id,
          name: ((user.first_name || '') + ' ' + (user.name || '')),
        }
      })
    },
    promotionInternsInfo() {
      if (!this.promotionInterns)
        return {}

      return this.promotionInterns.reduce((dictionary, intern) => {
        dictionary[intern.id] = intern

        return dictionary
      }, {})
    },
    sequenceList() {
      if (!this.course || !this.course.modules)
        return []

      return this.course.modules.reduce((list, module) => {
        module.sequences.forEach((sequence) => {
          list.push({
            id: sequence.id,
            name: 'Niveau ' + (module.order + 1) + ' - ' + (sequence.name || 'Séquence ' + (sequence.order + 1)) + ' - ' + sequence.title,
          })
        })

        return list
      }, [])
    },
    sequenceInfo() {
      if (!this.sequenceList)
        return {}

      return this.sequenceList.reduce((dictionary, sequence) => {
        dictionary[sequence.id] = sequence

        return dictionary
      }, {})
    },
  },
  watch: {
    sequenceList: {
      handler(newList) {
        // Set default value
        this.sequence = (newList && newList.length > 0 ? newList[0] : null)
      },
      immediate: true,
    },
    promotionInterns: {
      handler(newList) {
        // Set default value
        this.intern = (newList && newList.length > 0 ? newList[0] : null)
      },
      immediate: true,
    },
  },
  methods: {
    formatDate(date){
      return moment(new Date(date)).locale('fr').format('L')
    },
    onAddInternSequence() {
      const startDate = new Date(this.startDate)
      const endDate = new Date(this.endDate)

      if (!startDate || isNaN(startDate) || !endDate || isNaN(endDate))
        return
      
      this.$emit('add-user-sequence', {
        id: this.sequence.id,
        startDate: startDate,
        endDate: endDate,
        userID: this.intern.id,
      })
    },
    onRemoveInternSequence(internSequence) {
      this.$emit('remove-user-sequence', internSequence)
    },
  },
};
</script>

<style lang="scss">
</style>