import Rete from 'rete'
import CustomNode from './CustomNode.vue'
import * as Socket from '../sockets'
import { AssetControl } from '../controls/AssetControl.js'
import { TextControl } from '../controls/TextControl.js'

export class VideoComponent extends Rete.Component {
  constructor(node_type) {
    // Use the node type slug as component name
    super(node_type.slug)

    this.data = {
      render: 'vue',
      component: CustomNode,
      props: {
        title: node_type.name,
        description: node_type.description,
      }
    }
  }

  builder(node) {
    const next = new Rete.Output('next', 'Noeud suivant', Socket.trigger, false)//todo: comon ?
    const previous = new Rete.Input('previous', '', Socket.triggered, true)
    const title = new TextControl('title', 'Titre', false)
    const video = new AssetControl(this.name, null, ['file_video'], true)
    
    return node
      .addInput(previous)
      .addControl(title)
      .addControl(video)
      .addOutput(next)
  }
}
