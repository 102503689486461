<template>
	<div v-if="game">
		<!-- Label list -->
		<div class="label-zone mb-5">
			<div class="uppercase font-principal-bold text-sm">
				Zone de creation de label
			</div>

			<div class="flex flex-row mt-2 max-h-full flex-wrap">
				<div class="label flex-center" v-for="(label, i) in labels" :key="i">
					<div
						class="content"
						contenteditable="true"
						v-text="label.text"
						@blur="updateLabel({ label, text: $event.target.innerText })"
					></div>
					<div class="flex close" @click="removeLabel(i)">
						<b-icon-x></b-icon-x>
					</div>
				</div>

				<div
					class="ml-2 cursor-pointer flex-center text-xl text-principal-list-item"
					@click="addLabel"
				>
					+
				</div>
			</div>
		</div>

		<!-- Table -->
		<div class="uppercase font-principal-bold text-sm">Tableau {{ game.type.name }}</div>

		<GameTable
			:game="game"
			@add-column="addColumn"
			@add-line="addLine"
			@update-header="updateHeader"
			@delete-column="deleteColumn"
			@delete-line="deleteLine"
			:assets="assets"
		>
			<template slot-scope="{ question }">
				<div
					v-if="question && question.answers && question.answers.length > 0"
					class="h-full flex-row flex-wrap flex-center w-full"
					@click="OpenPanel(question)"
				>
					<div
						class="label m-2 flex flex-row flex-center"
						v-for="answer in question.answers"
						:key="answer.id"
					>
						<div class="text-xs">
							{{ answer.text }}
						</div>
					</div>
				</div>
				<div v-else class="h-full flex-center w-full" @click="OpenPanel(question)">
					Ajouter un label
				</div>
			</template>
		</GameTable>
		<MiddlePanel title="Choix du label" ref="panel">
			<b-list-group class="label-zone twn-shadow mx-5 mb-5">
				<b-form-checkbox-group v-model="selectedLabels" class="flex flex-col m-auto">
					<b-form-checkbox
						@change="selectLabel($event, i)"
						:value="i"
						v-for="(label, i) in labels"
						:key="i"
						class="my-1"
						>{{ label.text }}</b-form-checkbox
					>
				</b-form-checkbox-group>
				<!-- <b-list-group-item
					button
					v-for="(label, i) in labels"
					:key="i"
					class="bg-transparent flex flex-row justify-between"
					@click="selectLabel(i)"
				> -->
				<!-- </b-list-group-item> -->
			</b-list-group>
		</MiddlePanel>
		<div class="my-4">
			Correction:
			<b-form-textarea v-model="correction"></b-form-textarea>
		</div>
	</div>
</template>

<script>
import GameTable from "@/components/common/game/Table"
import MiddlePanel from "@/components/MiddlePanel"
import {mapState } from 'vuex'
export default {
	name: "DragDrop",
	components: {
		GameTable,
		MiddlePanel
	},
	props: {
		game: {
			type: Object,
			required: true,
			default: null
		}
	},
	data() {
		return {
			labels: [],
			tabOptions: [
				{
					title: "Labels"
				}
			],
			selectedQuestion: null,
			selectedLabels: []
		}
	},
	created() {
		if (this.questions.length > 0) {
			this.labels = this.questions.reduce((array, question, index) => {
				for (var i = 0; i < question.answers.length; i++) {
					let answer = question.answers[i]

					let sameLabel = array.find((el) => el.text === answer.text)
					if (sameLabel) {
						sameLabel.answers.push({ question: index, answer: i })
					} else {
						array.push({
							text: answer.text,
							answers: [
								{
									question: index,
									answer: i
								}
							]
						})
					}
				}
				return array
			}, [])
		} else {
			this.addLine({ lineCount: 0, columnCount: 0 })
			this.addColumn({ lineCount: 1, columnCount: 0 })
		}
	},
	computed: {
		...mapState("Assets", {
			assets(state) {
				if (!state.assets || state.assets.length <= 0) return {}

				return state.assets.reduce((dict, asset) => {
					dict[asset.id] = asset

					return dict
				}, {})
			}
		}),
		questions() {
			if (!this.game) return []
			return JSON.parse(JSON.stringify(this.game.questions))
		},
		correction:{
			get(){
				return this.game.correction
			},
			set(value){
				this.$emit('update-correction', value)
			}
		},
	},
	methods: {
		addLabel() {
			this.labels.push({
				text: "Label " + this.labels.length,
				answers: []
			})
		},
		removeLabel(i) {
			if (this.labels[i].questionIndex === null || this.labels[i].answerIndex === null) {
				this.labels.splice(i, 1)
			} else {
				this.labels[i].answers.forEach((a) => {
					let answers = [...this.questions[a.question].answers]

					let toDelete = answers.findIndex((an) => an.text == this.labels[i].text)
					answers.splice(toDelete, 1)
					this.$emit("update-question", a.question, {
						answers
					})
				})
				this.labels.splice(i, 1)
			}
		},
		updateLabel({ label, text }) {
			label.text = text

			label.answers.forEach((a) => {
				let answers = [...this.questions[a.question].answers]

				answers[a.answer].text = text
				this.$emit("update-question", a.question, {
					answers
				})
			})
		},
		OpenPanel(question) {
			this.selectedQuestion = null
			this.$refs["panel"].show()
			this.selectedQuestion = this.game.questions.indexOf(question)
			this.selectedLabels = []
			this.labels.forEach((l, index) => {
				if (l.answers.find((a) => a.question === this.selectedQuestion)) {
					this.selectedLabels.push(index)
				}
			})
		},
		HidePanel() {
			this.selectedLabels = []
			this.selectedQuestion = null
			this.$refs["panel"].hide()
		},
		selectLabel(e, i) {
			if (this.selectedQuestion !== null && this.selectedQuestion !== -1) {
				let answers = [...this.questions[this.selectedQuestion].answers]
				//Check if there's an answer with the same text as the label
				let sameIndex = answers.findIndex((a) => a.text === this.labels[i].text)
				if (sameIndex === -1) {
					let newAnswer = {
						text: this.labels[i].text,
						is_correct: true
					}
					let len = answers.push(newAnswer)
					this.labels[i].answers.push({
						question: this.selectedQuestion,
						answer: len - 1
					})
				}
				//If there's an answer with the same text, remove the answer
				else {
					answers.splice(sameIndex, 1)
					let toDelete = this.labels[i].answers.findIndex(
						(a) => a.question == this.selectedQuestion && a.answer == sameIndex
					)
					this.labels[i].answers.splice(toDelete, 1)
				}
				this.$emit("update-question", this.selectedQuestion, {
					answers: answers
				})
			}
		},
		addLine({ lineCount, columnCount }) {
			this.$emit("add-headers", [
				{
					content: "",
					line: lineCount,
					column: null
				}
			])

			let questions = []

			for (var i = 0; i < columnCount; i++) {
				questions.push({
					answers: [],
					text: "",
					column: i,
					line: lineCount,
					correction: ""
				})
			}

			this.$emit("add-questions", questions)
		},
		addColumn({ lineCount, columnCount }) {
			this.$emit("add-headers", [
				{
					content: "",
					line: null,
					column: columnCount
				}
			])

			let questions = []

			for (var i = 0; i < lineCount; i++) {
				questions.push({
					answers: [],
					text: "",
					column: columnCount,
					line: i,
					correction: ""
				})
			}

			this.$emit("add-questions", questions)
		},
		deleteLine(line) {
			// Find header for this line and remove it
			let headerIndex = null

			for (var i = 0; i < this.game.headers.length; i++) {
				if (this.game.headers[i].line == line) {
					headerIndex = i
				} else if (this.game.headers[i].line > line) {
					const header = this.game.headers[i]

					this.$emit("update-header", i, {
						line: header.line - 1
					})
				}
			}

			if (headerIndex != null) {
				this.$emit("delete-headers", [headerIndex])
			}

			// Find questions on this line and remove them
			let questionIndexes = []

			for (var j = 0; j < this.game.questions.length; j++) {
				if (this.game.questions[j].line == line) {
					questionIndexes.push(j)
				} else if (this.game.questions[j].line > line) {
					const question = this.game.questions[j]

					this.$emit("update-question", j, {
						line: question.line - 1
					})
				}
			}

			this.$emit("delete-questions", questionIndexes)
		},
		deleteColumn(column) {
			// Find header for this column and remove it
			let headerIndex = null

			for (var i = 0; i < this.game.headers.length; i++) {
				if (this.game.headers[i].column == column) {
					headerIndex = i
				} else if (this.game.headers[i].column > column) {
					const header = this.game.headers[i]

					this.$emit("update-header", i, {
						column: header.column - 1
					})
				}
			}

			if (headerIndex != null) {
				this.$emit("delete-headers", [headerIndex])
			}

			// Find questions on this column and remove them
			let questionIndexes = []

			for (var j = 0; j < this.game.questions.length; j++) {
				if (this.game.questions[j].column == column) {
					questionIndexes.push(j)
				} else if (this.game.questions[j].column > column) {
					const question = this.game.questions[j]

					this.$emit("update-question", j, {
						column: question.column - 1
					})
				}
			}

			this.$emit("delete-questions", questionIndexes)
		},
		updateHeader(i, data) {
			this.$emit("update-header", i, data)
		}
	}
}
</script>

<style lang="scss">
.label {
	@apply flex-row rounded-full px-3 py-1 m-2;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

	.content {
		@apply ml-1 text-sm;
		min-width: 1rem;
		min-height: 1rem;
	}
}
</style>
