import BrowserDetector from 'browser-dtector'

import userFieldsValues from '@/constants/userFieldsValues'

const formatedFieldsValues = {}

for (const fieldKey in userFieldsValues) {
	formatedFieldsValues[fieldKey] = userFieldsValues[fieldKey].reduce((dict, fieldValue) => {
		dict[fieldValue.value] = fieldValue

		return dict
	}, {})
}

const value = () => ''

const filtered = (arrayOfObject, field, value) => {
	return arrayOfObject.filter((object) => {
		return (object[field] == value)
	})
}

const groupByUniq = (list, field, transform = null) => {
	return list.reduce((dict, item) => {
		if (transform) {
			item = transform(item)
		}

		dict[item[field]] = item

		return dict
	}, {})
}

const groupBy = (list, field) => {
	return list.reduce((dict, item) => {
		if (!dict[item[field]])
			dict[item[field]] = []

		dict[item[field]].push(item)

		return dict
	}, {})
}

const countMatch = (list, filter) => {
	return list.filter(filter).length
}

const countDistinct = (list, field, filterNullable = false) => {
	let keys = Object.keys(groupByUniq(list, field))

	if (filterNullable) {
		keys = keys.filter((key) => {
			return (key ? true : false)
		})
	}

	return keys.length
}

const weekId = (date) => {
	// Generate a uniq and ordered week id for a date
	const weekDate = new Date(date.getTime())

	// Set to start of the week (previous monday)
	weekDate.setDate((weekDate.getDate() - (6 + weekDate.getDay()) % 7))

	// Create week id with Day+Month+Year
	return (weekDate.getFullYear() * 10000) + (weekDate.getMonth() * 100) + weekDate.getDate()
}

const dateFromWeekId = (weekId) => {
	return new Date(Math.trunc(weekId / 10000), Math.trunc((weekId % 10000) / 100), (weekId % 100))
}

const fillMissingWeek = (data, fillMissing, from = null, to = new Date()) => {
	// Check for missing week id
	const sortedWeekIds = Object.keys(data).map((k) => parseInt(k, 10)).sort()
    
    if (sortedWeekIds.length > 0) {
        // Get first week start date
        const firstWeekId = (from ? weekId(from) : sortedWeekIds[0])
        const firstMonday = dateFromWeekId(firstWeekId)
        
        // Get all weekIDs and add missing ones
        const oneWeek = (7 * 24 * 60 * 60 * 1000)
        const weekCount = Math.round((to.getTime() - firstMonday.getTime()) / oneWeek)

        let currentDate = firstMonday
        for (let i = 0; i < weekCount; i++) {
            const id = weekId(currentDate)
            
            if (!data[id]) {
                data[id] = fillMissing(id)
            }

            // Move to next week
            currentDate = new Date(currentDate.getTime() + oneWeek)
        }
    }

    return data
}

const groupByWeek = (list, weekIdField, from = null, to = new Date()) => {
	// Group by weekIdField
	let data = groupBy(list, weekIdField)

	// Check for missing week and fill with empty array
    return fillMissingWeek(data, () => [], from, to)
}

const groupAverage = (dict, transform) => {
	const keys = Object.keys(dict)

	if (keys.length <= 0)
		return 0.0

	const count = keys.reduce((count, key) => {
		count += transform(dict[key])

		return count
	}, 0)

	return (count / keys.length)
}

const sumField = (list, field, subfield = null) => {
	let reduce = (sum, item) => {
		sum += item[field]

		return sum
	}

	if (subfield) {
		reduce = (sum, item) => {
			sum += item[field][subfield]

			return sum
		}
	}

	return list.reduce(reduce, 0)
}

const maxField = (list, field, subfield = null) => {
	let reduce = (max, item) => {
		if (item[field] > max) {
			max = item[field]
		}

		return max
	}

	if (subfield) {
		reduce = (max, item) => {
			if (item[field][subfield] > max) {
				max = item[field][subfield]
			}

			return max
		}
	}

	return list.reduce(reduce, 0)
}

const averageField = (list, field, subfield = null) => {
	if (list.length <= 0)
		return 0

	return (sumField(list, field, subfield) / list.length)
}

const averageValues = (list) => {
	if (list.length <= 0)
		return 0

	const sum = list.reduce((sum, value) => {
		sum += value 
		return sum
	}, 0)

	return (sum / list.length)
}

const experiencesLabels = userFieldsValues.experiences.reduce((dict, field) => {
	dict[field.value] = field.label
	return dict
}, {})

// One day in ms
const oneDay = (1 * 60 * 60 * 1000 * 24)

export default {
	types: {
		float: '#0.00',
		percentage: '0.00%',
		duration: {
			format: '[h]"h" mm"min"',
			transform: (time) => (time / oneDay)
		},
		date: {
			format: 'dd/MM/yyyy "à" HH"h"mm',
			transform: (time) => (time ? ((time / (86400 * 1000)) + 25569) : false) // Excel datetime value format
		}
	},
	remoteData: {
		users: {
			module: 'Logs',
			action: 'GetUsersTrackingData',
			payload: {
				logTypes: ['login', 'pdf_view', 'video_end', 'scenario_end', 'user_agent']
			},
			groupByUniq: 'id'
		},
		promotions: {
			module: 'Logs',
			action: 'GetPromotionsTrackingData',
			groupByUniq: 'id'
		},
		scenarios: {
			module: 'Scenarios',
			action: 'getList',
			state: 'list',
			groupByUniq: 'id'
		}
	},
	extraData: {
		firstCreatedUserDate(data) {
			if (!data.users || data.users.length <= 0)
				return (new Date())

			let firstCreatedTime = (new Date(data.users[0].created_at)).getTime()

			data.users.forEach((user) => {
				const createdTime = (new Date(user.created_at)).getTime()

				if (createdTime < firstCreatedTime) {
					firstCreatedTime = createdTime
				}
			})

			return (new Date(firstCreatedTime))
		},
		promotions: {
			scenarios(promotion) {
				// Get scenario list for this promotion course
				return promotion.course?.modules?.reduce((scenarios, mod) => {
					// Get scenarios in this module sequences
					mod.sequences.forEach((sequence) => {
						scenarios = scenarios.concat(sequence.scenarios)
					})

					return scenarios
				}, []) || []
			},
		},
		users: {
			createdWeekId(user) {
				// Generate a uniq week id for the user subscription date
				return weekId(new Date(user.created_at))
			},
			activeTimeData(user, data) {
				// Init computed data
				let computedTimeData = {
					times: [],
					sum: 0,
					max: 0,
					average: 0,
					weekIds: {},
					finalTests: [],
					finalTestsDone: [],
					finalTestsScores: [],
					lastFinalTestDone: null,
					lastSessionTimestamp: 0
				}

				// Get all event date times
				const times = user.logs.reduce((times, log) => {
					// Add log creation time
					times.push((new Date(log.created_at)).getTime())

					// Add results dates and check for final test data
					if (log?.data?.results && log.data.results.length) {
						const isFinalTest = (data.scenariosById[log.data_key] ? (data.scenariosById[log.data_key].type.slug == 'final_test') : false)

						if (isFinalTest) {
							computedTimeData.finalTests.push(log.data_key)
						}

						log.data.results.forEach((result) => {
							const resultTimestamp = (new Date(result.date)).getTime()
							times.push(resultTimestamp)

							if (isFinalTest) {
								computedTimeData.finalTestsScores.push(result.score)

								if (result.score >= 80) { // todo: dynamic score !?
									// Check if we don't have a last test done or if the current test has been done after the last one and already done previously (to handle the fact that results are not sorted by date)
									if (!computedTimeData.lastFinalTestDone || (resultTimestamp > computedTimeData.lastFinalTestDone.timestamp && computedTimeData.finalTestsDone.indexOf(log.data_key) < 0)) {
										computedTimeData.lastFinalTestDone = { id: log.data_key, timestamp: resultTimestamp }
									}

									computedTimeData.finalTestsDone.push(log.data_key)
								}
							}
						})
					}

					return times
				}, []).sort()

				// Get all data by spliting user logs in sessions
				const thirtyMinutes = (30 * 60 * 1000)
				const fiveMinutes = (5 * 60 * 1000)

				let lastLogTime = 0

				times.forEach((logTime) => {
					const deltaTime = (logTime - lastLogTime)

					// Check if two logs are too far apart
					if (deltaTime > thirtyMinutes) {
						// Start a new session with the minimal time (5 min)
						computedTimeData.lastSessionTimestamp = logTime
						computedTimeData.times.push(fiveMinutes)

						// Update sum
						computedTimeData.sum += fiveMinutes

						// Register session week as active
						computedTimeData.weekIds[weekId(new Date(logTime))] = true
					} else {
						// Increment current session time
						computedTimeData.times[computedTimeData.times.length - 1] += deltaTime

						// Update sum
						computedTimeData.sum += deltaTime
					}

					// Update max
					const sessionTime = computedTimeData.times[computedTimeData.times.length - 1]

					if (sessionTime > computedTimeData.max) {
						computedTimeData.max = sessionTime
					}
					
					lastLogTime = logTime
				})

				// Compute average time
				if (computedTimeData.times.length > 0) {
					computedTimeData.average = (computedTimeData.sum / computedTimeData.times.length)
				}

				return computedTimeData
			},
			progression(user, data) {
				// Get scenario done by id
				const scenarioDoneById = user.logs.reduce((dict, log) => {
					if (log.type.slug == 'scenario_end') {
						dict[log.data_key] = true
					}

					return dict
				}, {})

				// Get number of scenarios done and available to this user
				const progression = user.promotions.reduce((progression, promotion) => {
					// Count scenario done in this promotion
					data.promotionsById[promotion.id].scenarios.forEach((scenario) => {
						const isFinalTest = (data.scenariosById[scenario.id] ? (data.scenariosById[scenario.id].type.slug == 'final_test') : false)

						if (scenarioDoneById[scenario.id] && (!isFinalTest || user.activeTimeData.finalTestsDone.indexOf(scenario.id) > -1)) {
							progression.done += 1
						}
					})

					// Add scenario available in this promotion
					progression.available += data.promotionsById[promotion.id].scenarios.length

					return progression
				}, {
					done: 0,
					available: 0
				})

				// Return progression percentage for this user
				if (progression.available <= 0)
					return 0
				
				return (progression.done / progression.available)
			},
		},
		devices(data) {
			// Get the percentage of user using mobile/table or desktop devices
			let total = 0
			const userAgentParser = new BrowserDetector()

			const counts = data.users.reduce((counts, user) => {
				user.logs.forEach((log) => {
					if (log.type.slug === 'user_agent' && log.data) {
						const userAgent = userAgentParser.parseUserAgent(log.data)
						const type = (userAgent.isMobile ? 'Mobile' : (userAgent.isTablet ? 'Tablet' : 'Desktop'))

						if (!type) {
							return
						}

						if (!counts[type]) {
							counts[type] = 0
						}

						counts[type] += 1
						total += 1
					}
				})

				return counts
			}, {})

			const keys = Object.keys(counts)

			return keys.reduce((list, type) => {
				list.push({
					type,
					percentage: (counts[type] / total)
				})

				return list
			}, [])
		}
	},
	sheets: [
		{
			name: 'Table',
			content: [
				{
					table: 'users',
					headers: ['Nom', 'Prénom', 'Status', 'Promotions', 'Nombre de connexions', 'Durée moyenne de session', 'Nombre moyen d\'évaluations validées par session', 'Progression du stagiaire', 'Nom de la dernière évaluation réussie', 'Score moyen global (toutes tentatives confondues)', 'Dernière connexion'],
					row: (user, data) => {
						const timeData = user.activeTimeData

						return [
							'name',
							'first_name',
							{ value: (user.activeTimeData.sum > 0 ? 'Actif' : 'Inactif') },
							{ value: user.promotions.map((promotion) => data.promotionsById[promotion.id].title).join(', ') },
							{ value: timeData.times.length },
							{ value: timeData.average, type: 'duration' },
							{
								value: (timeData.times.length > 0 ? timeData.finalTestsDone.length / timeData.times.length : 0),
								type: 'float'
							},
							{ value: user.progression, type: 'percentage' },
							{
								value: (timeData.lastFinalTestDone ? data.scenariosById[timeData.lastFinalTestDone.id].name : '')
							},
							{
								value: averageValues(timeData.finalTestsScores),
								type: 'float'
							},
							{
								value: timeData.lastSessionTimestamp,
								type: 'date'
							}
						]
					},
				}
			]
		},
		{
			name: 'Information utilisateurs',
			content: [
				{
					table: 'users',
					headers: ['Nom', 'Prénom', 'Status', 'Promotions', 'Genre', 'Age', 'Travailleur(se) handicapé(e)', 'Niveau d\'éducation', 'Poste', 'Temps de travail', 'Souhaite un temps augmenté de travail', 'Contrat', 'Années d\'expériences', 'A déjà suivi une formation professionnelles', 'Formation(s) suivie(s)', 'A déjà suivi une formation en elearning', 'Attente(s)', 'Autres besoins'],
					row: (user, data) => {
						return [
							'name',
							'first_name',
							{ value: (user.activeTimeData.sum > 0 ? 'Actif' : 'Inactif') },
							{ value: user.promotions.map((promotion) => data.promotionsById[promotion.id].title).join(', ') },
							{ value: formatedFieldsValues.gender[user.gender]?.label },
							{ value: formatedFieldsValues.age[user.age]?.label },
							{ value: (user.is_disabled_worker ? 'Oui' : 'Non') },
							{ value: formatedFieldsValues.level_studies[user.level_studies]?.label },
							'actual_position',
							'actual_working_time',
							{ value: (user.rise_working_time ? 'Oui' : 'Non') },
							{ value: formatedFieldsValues.contract[user.contract]?.label },
							{ value: formatedFieldsValues.experiences[user.experiences]?.label },
							{ value: (user.has_professional_courses ? 'Oui' : 'Non') },
							'professional_courses',
							{ value: (user.has_distance_learning ? 'Oui' : 'Non') },
							'expectations',
							'additionnal_needs',
						]
					},
				}
			]
		},
		{
			name: 'Navigateurs',
			content: [
				{
					table: 'devices',
					headers: ['Type', 'Pourcentage'],
					row: (device) => {
						return [
							{ value: device.type },
							{ value: device.percentage, type: 'percentage' }
						]
					},
				}
			]
		},
		{
			// name: 'Main',
			content: [
				{ text: 'DONNEES D\'INSCRIPTION' },
				{},
				{ text: 'Nb d\'utilisateurs' },
				{
					text: 'Tous',
					value: (data) => data.users.length,
				},
				{
					text: (groupName) => (experiencesLabels[groupName] || ''),
					value: (groupData) => groupData.length,
					groupNames: Object.keys(experiencesLabels),
					groupBy: {
						list: 'users',
						field: 'experiences'
					}
				},
				{},
				{},
				{ text: 'Nb d\'utilisateurs ayant terminé' },
				{
					text: 'Tous',
					value: (data) => countMatch(data.users, (user) => {
						return (user.progression == 1)
					})
				},
				{
					text: (groupName) => (experiencesLabels[groupName] || ''),
					value: (groupData) => countMatch(groupData, (user) => {
						return (user.progression == 1)
					}), 
					groupNames: Object.keys(experiencesLabels),
					groupBy: {
						list: 'users',
						field: 'experiences'
					}
				},
				{},
				{ text: 'Nb d\'utilisateurs en cours' },
				{
					text: 'Tous',
					value: (data) => countMatch(data.users, (user) => {
						return (user.progression > 0 && user.progression < 1)
					})
				},
				{
					text: (groupName) => (experiencesLabels[groupName] || ''),
					value: (groupData) => countMatch(groupData, (user) => {
						return (user.progression > 0 && user.progression < 1)
					}), 
					groupNames: Object.keys(experiencesLabels),
					groupBy: {
						list: 'users',
						field: 'experiences'
					}
				},
				{},
				{
					text: 'Nb de résidence (ID d\'acquisition) ayant au moins 1 inscrit',
					value: (data) => countDistinct(data.users, 'experiences', true)
				},
				{},
				{ text: 'Nb de nouveau utilisateurs par semaine (en moyenne)' },
				{
					text: 'Tous',
					value: (data) => groupAverage(groupByWeek(data.users, 'createdWeekId', data.firstCreatedUserDate), (groupByWeekData) => groupByWeekData.length),
					type: 'float'
				},
				{
					text: (groupName) => (experiencesLabels[groupName] || ''),
					value: (groupData, data) => groupAverage(groupByWeek(groupData, 'createdWeekId', data.firstCreatedUserDate), (groupByWeekData) => groupByWeekData.length), 
					type: 'float',
					groupNames: Object.keys(experiencesLabels),
					groupBy: {
						list: 'users',
						field: 'experiences'
					}
				},
				{},
				{
					text: 'Taux d\'inscription',
					value: (data) => (countMatch(data.users, (user) => (user.progression > 0)) / data.users.length),
					type: 'percentage'
				},
				{},
				{},
				{ text: 'DONNEES DE CONNEXION' },
				{},
				{ text: 'Durée totale de connexion' },
				{
					text: 'Pour tous les utilisateurs',
					value: (data) => sumField(data.users, 'activeTimeData', 'sum'),
					type: 'duration'
				},
				{
					text: (groupName) => ('Pour les utilisateurs ' + (experiencesLabels[groupName] || '').toLowerCase()),
					value: (groupData) => sumField(groupData, 'activeTimeData', 'sum'),
					type: 'duration',
					groupNames: Object.keys(experiencesLabels),
					groupBy: {
						list: 'users',
						field: 'experiences'
					}
				},
				{},
				{ text: 'Durée moyenne de connexion par utilisateur' },
				{
					text: 'Pour tous les utilisateurs',
					value: (data) => averageField(data.users, 'activeTimeData', 'average'),
					type: 'duration'
				},
				{
					text: (groupName) => ('Pour les utilisateurs ' + (experiencesLabels[groupName] || '').toLowerCase()),
					value: (groupData) => averageField(groupData, 'activeTimeData', 'average'),
					type: 'duration',
					groupNames: Object.keys(experiencesLabels),
					groupBy: {
						list: 'users',
						field: 'experiences'
					}
				},
				{},
				{ text: 'Durée maximale de connexion' },
				{
					text: 'Pour tous les utilisateurs',
					value: (data) => maxField(data.users, 'activeTimeData', 'max'),
					type: 'duration'
				},
				{
					text: (groupName) => ('Pour les utilisateurs ' + (experiencesLabels[groupName] || '').toLowerCase()),
					value: (groupData) => maxField(groupData, 'activeTimeData', 'max'),
					type: 'duration',
					groupNames: Object.keys(experiencesLabels),
					groupBy: {
						list: 'users',
						field: 'experiences'
					}
				},
				{},
				{ text: 'Nb d\'utilisateurs actifs par semaine (en moyenne)' },
				{
					text: 'Pour tous les utilisateurs',
					value: (data) => {
						// Init weeks dictionary
						let activeUsersPerWeek = fillMissingWeek({}, () => 0, data.firstCreatedUserDate)

						// Count active users
						activeUsersPerWeek = data.users.reduce((dict, user) => {
							const weekIds = Object.keys(user.activeTimeData.weekIds)

							weekIds.forEach((weekId) => {
								if (!dict[weekId])
									dict[weekId] = 0

								dict[weekId] += 1
							})

							return dict
						}, {})

						// Return average
						return groupAverage(activeUsersPerWeek, (activeUsersCount) => activeUsersCount)
					},
					type: 'float'
				},
				{
					text: (groupName) => ('Pour les utilisateurs ' + (experiencesLabels[groupName] || '').toLowerCase()),
					value: (groupData, data) => {
						// Init weeks dictionary
						let activeUsersPerWeek = fillMissingWeek({}, () => 0, data.firstCreatedUserDate)

						// Count active users
						activeUsersPerWeek = groupData.reduce((dict, user) => {
							const weekIds = Object.keys(user.activeTimeData.weekIds)

							weekIds.forEach((weekId) => {
								if (!dict[weekId])
									dict[weekId] = 0

								dict[weekId] += 1
							})

							return dict
						}, {})

						// Return average
						return groupAverage(activeUsersPerWeek, (activeUsersCount) => activeUsersCount)
					},
					type: 'float',
					groupNames: Object.keys(experiencesLabels),
					groupBy: {
						list: 'users',
						field: 'experiences'
					}
				},
				{},
				{ text: 'DONNEES PEDAGOGIQUES' },
				{},
				{ text: 'Taux d\'avancement moyen' },
				{
					text: 'Pour tous les utilisateurs',
					value: (data) => averageField(data.users, 'progression'),
					type: 'percentage'
				},
				{
					text: (groupName) => ('Pour les utilisateurs ' + (experiencesLabels[groupName] || '').toLowerCase()),
					value: (groupData) => averageField(groupData, 'progression'),
					type: 'percentage',
					groupNames: Object.keys(experiencesLabels),
					groupBy: {
						list: 'users',
						field: 'experiences'
					}
				},
				{},
				{ text: 'Taux d\'avancement moyen Episodes' },
				{
					text: 'Taux d\'avancement moyen EP1',
					value
				},
				{
					text: 'Taux d\'avancement moyen EP2',
					value
				},
				{
					text: 'Taux d\'avancement moyen EP3',
					value
				},
				{
					text: 'Taux d\'avancement moyen EP4',
					value
				},
				{
					text: 'Taux d\'avancement moyen EP5',
					value
				},
				{
					text: 'Taux d\'avancement moyen EP6',
					value
				},
				{},
				{ text: 'Taux de réussite moyen aux quiz' },
				{
					text: 'Pour tous les utilisateurs',
					value
				},
				{
					text: 'pour les utilisateurs locataires',
					value
				},
				{
					text: 'pour les utilisateurs copropriétaires',
					value
				},
				{
					text: 'pour les utilisateurs autres',
					value
				},
				{},
				{ text: 'Taux de réussite moyen Episodes' },
				{
					text: 'Taux de réussite moyen EP1',
					value
				},
				{
					text: 'Taux de réussite moyen EP2',
					value
				},
				{
					text: 'Taux de réussite moyen EP3',
					value
				},
				{
					text: 'Taux de réussite moyen EP4',
					value
				},
				{
					text: 'Taux de réussite moyen EP5',
					value
				},
				{
					text: 'Taux de réussite moyen EP6',
					value
				}
			],
		}
	],
}