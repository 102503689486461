import apollo from '@/apolloClient'
import { GC_GET_COURSE_LIST, GC_GET_COURSE_TYPES, GC_ADD_COURSE_ONE, GC_GET_COURSE, GC_DELETE_COURSE, GC_UPDATE_COURSE, GC_CHECK_NAME } from '@/graphql/course'
import { GC_GET_SCENARIO_TYPE_LIST } from '@/graphql/scenario'

export default {
    namespaced: true,
    state:{
        list: [],
        types:[],
        scenarioTypes: []
    },
    mutations: {
        SET_COURSE_LIST(state, courses) {
            state.list = (courses || [])
        },
    },
    getters:{
        typeListFormat(state){
            return state.types.reduce((dict, type) => {
                dict[type.id] = type

                return dict
            }, {})
        },
    },
    actions:{
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_COURSE_LIST })

            commit('SET_COURSE_LIST', response.data.course)
        },
        async Delete({state}, id){
            const response = await apollo.mutate({
                mutation: GC_DELETE_COURSE,
                variables: {id}
            })

            return response.data.delete_course_by_pk
        },
        async GetCourse({state}, id){
            const response = await apollo.query({
                query: GC_GET_COURSE,
                variables: {
                    id
                }
            })

            return response.data.course_by_pk
        },
        async GetCourseTypes({state}){
            const response = await apollo.query({
                query:GC_GET_COURSE_TYPES
            })

            state.types = response.data.course_type
        },
        async GetScenarioTypes({state}){
            const response = await apollo.query({
                query: GC_GET_SCENARIO_TYPE_LIST
            })

            state.scenarioTypes = response.data.course_type;
        },
        async NameTaken({state},{name, id}){
            const response = await apollo.query({
                query: GC_CHECK_NAME,
                variables: {
                    name
                }
            })

			if (
				(response.data.course.length > 0 && !id) ||
				(response.data.course.length > 0 && id && response.data.course[0].id !== id)
			) {
                return true
            }
        },
        async saveCourse({state, dispatch}, course){
            // Check name (todo:)
            let isTaken = await dispatch('NameTaken', {name: course.name, id:course.id})

            if (isTaken){
                return false;
            }

    //         // todo: Sanity check for course of type bloc
    //         if (course.type && course.type.slug == 'bloc_course') {
				// // Delete modules > 0
				// if (course.modules.length > 1){
				// 	course.modules.splice(1, course.modules.length - 1);
				// }
    //         }

            // Add course base data
            let variables = {
                name: (course.name || ''),
                description: (course.description || ''),
                type: course.type,
            }

            // Format modules data
            variables.modules = {
                data: course.modules.map((module, index) => {
                    const data = {
                        name: (module.name || ''),
                        title: (module.title || ''),
                        order: (module.order || index),
                        scenarios: {
                            data: module.scenarios.map((scenario) => {
                                return {
                                    scenario_id: scenario.id,
                                }
                            }),
                        },
                        sequences: {
                            data: module.sequences.map((sequence, index) => {
                                // Get full list of associated scenarios for both scenario type: revision and scenario
                                let scenarios = []

                                if (sequence.scenarios) {
                                    scenarios = scenarios.concat(
                                        sequence.scenarios.map((scenario, index) => {
                                            return {
                                                scenario_id: scenario.id,
                                                order: (scenario.order || index),
                                            }
                                        })
                                    )
                                }
                                
                                if (sequence.revisions) {
                                    scenarios = scenarios.concat(
                                        sequence.revisions.map((scenario, index) => {
                                            return {
                                                scenario_id: scenario.id,
                                                order: (scenario.order || index),
                                            }
                                        })
                                    )
                                }

                                // Get list of associated medias
                                let medias = []

                                if (sequence.medias) {
                                    medias = sequence.medias.map((media, index) => {
                                        return {
                                            media_id: media.id,
                                            order: (media.order || index),
                                        }
                                    })
                                }

                                const data = {
                                    name: (sequence.name || ''),
                                    title: (sequence.title || ''),
                                    module_scenario_limit: sequence.module_scenario_limit,
                                    order: (sequence.order || index),
                                    scenarios: {
                                        data: scenarios,
                                    },
                                    medias: {
                                        data: medias,
                                    },
                                }

                                if (sequence.id) {
                                    data.id = sequence.id
                                }

                                return data
                            }),
                        },
                    }

                    if (module.id) {
                        data.id = module.id
                    }

                    const course_id = (module.course_id || course.id)

                    if (course_id) {
                        data.course_id = course_id
                    }

                    return data
                }),
            }

            let response = null
            
            if (course.id) {
                // Set id
                variables.id = course.id

                // Get existing module ids
                const existingModules = variables.modules.data.filter((module) => {
                    return (module.id ? true : false)
                })

                variables.module_ids = existingModules.map((module) => module.id)

                // List all module scenario associations (existing or new) and create condition expression to delete old associations
                const moduleScenarioData = existingModules.reduce((data, module) => {
                    if (!module.scenarios.data)
                        return data

                    // Add new condition rules (delete existing associations not in the current list)
                    data.deleteCondition._or.push({
                        module_id: { _eq: module.id },
                        scenario_id: {
                            _nin: module.scenarios.data.reduce((ids, scenario) => {
                                if (scenario.scenario_id) {
                                    ids.push(scenario.scenario_id)
                                }

                                return ids
                            }, [])
                        },
                    })

                    // List all scenario associations in existing module
                    module.scenarios.data.forEach((scenario) => {
                        data.list.push({
                            module_id: module.id,
                            scenario_id: scenario.scenario_id,
                        })
                    })

                    return data
                }, {
                    deleteCondition: { _or: [] },
                    list: [],
                })

                variables.delete_module_scenarios_condition = moduleScenarioData.deleteCondition
                variables.module_scenario_list = moduleScenarioData.list

                // List all module sequences (existing or new) and create condition expression to delete old ones
                const moduleSequenceData = existingModules.reduce((data, module) => {
                    if (!module.sequences.data)
                        return data

                    // Add new condition rules (delete existing sequence not in the current list)
                    data.deleteCondition._or.push({
                        module_id: { _eq: module.id },
                        id: {
                            _nin: module.sequences.data.reduce((ids, sequence) => {
                                if (sequence.id) {
                                    ids.push(sequence.id)
                                }

                                return ids
                            }, [])
                        },
                    })

                    // List all sequence in existing module
                    module.sequences.data.forEach((sequence) => {
                        data.list.push({
                            id: sequence.id,
                            name: sequence.name,
                            order: sequence.order,
                            title: sequence.title,
                            module_scenario_limit: sequence.module_scenario_limit,
                            scenarios: sequence.scenarios,
                            medias: sequence.medias,
                            module_id: module.id,
                        })
                    })

                    return data
                }, {
                    deleteCondition: { _or: [] },
                    list: [],
                })

                variables.delete_sequence_condition = moduleSequenceData.deleteCondition
                variables.module_sequence_list = moduleSequenceData.list.map((sequence) => {
                    // Remove extra data (scenarios, ...) for existing ones
                    if (sequence.id) {
                        return {
                            id: sequence.id,
                            name: sequence.name,
                            title: sequence.title,
                            module_scenario_limit: sequence.module_scenario_limit,
                            order: sequence.order,
                            module_id: sequence.module_id,
                        }
                    }

                    return sequence
                })

                // List all sequence scenario and media associations (existing or new) and create condition expression to delete old associations
                const sequenceAssocData = moduleSequenceData.list.reduce((data, sequence) => {
                    if (!sequence.id)
                        return data

                    if (!sequence.scenarios.data && !sequence.medias.data)
                        return data

                    if (sequence.scenarios.data) {
                        // Add new condition rules (delete existing associations not in the current list)
                        data.deleteScenarioCondition._or.push({
                            sequence_id: { _eq: sequence.id },
                            scenario_id: {
                                _nin: sequence.scenarios.data.reduce((ids, scenario) => {
                                    if (scenario.scenario_id) {
                                        ids.push(scenario.scenario_id)
                                    }

                                    return ids
                                }, [])
                            },
                        })

                        // List all scenario associations in existing sequence
                        sequence.scenarios.data.forEach((scenario) => {
                            data.scenarioList.push({
                                sequence_id: sequence.id,
                                scenario_id: scenario.scenario_id,
                                order: scenario.order,
                            })
                        })
                    }

                    if (sequence.medias.data) {
                        // Add new condition rules (delete existing associations not in the current list)
                        data.deleteMediaCondition._or.push({
                            sequence_id: { _eq: sequence.id },
                            media_id: {
                                _nin: sequence.medias.data.reduce((ids, media) => {
                                    if (media.media_id) {
                                        ids.push(media.media_id)
                                    }

                                    return ids
                                }, [])
                            },
                        })

                        // List all media associations in existing sequence
                        sequence.medias.data.forEach((media) => {
                            data.mediaList.push({
                                sequence_id: sequence.id,
                                media_id: media.media_id,
                                order: media.order,
                            })
                        })
                    }

                    return data
                }, {
                    deleteScenarioCondition: { _or: [] },
                    scenarioList: [],
                    deleteMediaCondition: { _or: [] },
                    mediaList: [],
                })

                variables.delete_sequence_scenarios_condition = sequenceAssocData.deleteScenarioCondition
                variables.sequence_scenario_list = sequenceAssocData.scenarioList

                variables.delete_sequence_medias_condition = sequenceAssocData.deleteMediaCondition
                variables.sequence_media_list = sequenceAssocData.mediaList

                // Re-format modules data
                variables.modules = variables.modules.data.map((module) => {
                    // Remove extra data (sequences, scenarios, ...) for existing ones
                    if (module.id) {
                        return {
                            id: module.id,
                            name: module.name,
                            title: module.title,
                            order: module.order,
                            course_id: module.course_id,
                        }
                    }

                    return module
                })

                // Update course data
                response = await apollo.mutate({
                    mutation: GC_UPDATE_COURSE,
                    variables,
                })
            } else {
                // Insert new course data
                response = await apollo.mutate({
                    mutation: GC_ADD_COURSE_ONE,
                    variables: {
                        course: variables
                    }
                })

            }

            return response.data.insert_course_one
        }
    }
}