<template>
  <div class="text-control flex" :class="{ 'invalid': !isValid }">
    <!-- Label -->
    <span v-if="label" class="text-control-label">{{label}}</span>

    <!-- Input -->
    <input v-if="multiline <= 1" class="text-control-input twn-input" :type="inputType" :readonly="readonly" :value="value" @input="change($event)"/>

    <!-- Multiline texte area -->
    <textarea v-else class="text-control-input twn-input" :rows="multiline" :readonly="readonly" :value="value" @input="change($event)"></textarea>
  </div>
</template>


<script>
export default {
  props: {
    ikey: String,
    label: String,// todo:improve
    readonly: Boolean,
    multiline: Number,
    inputType: String,
    getData: Function,
    putData: Function,
  },
  data() {
    return {
      value: ''
    }
  },
  computed: {
    isValid() {
      // Soft validation (don't stop value updating, only show feedback)
      if (this.inputType == 'url') {
        return this.validateURL(this.value)
      }

      return true
    },
  },
  methods: {
    change(e) {
      this.value = e.target.value;
      this.update();
    },
    update() {
      if (this.ikey) this.putData(this.ikey, this.value);
    },
    validateURL(url) {
      if (!url)
        return true
      
      let regex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
      return regex.test(url);
    },
  },
  mounted() {
    this.value = this.getData(this.ikey);
  }
}
</script>

<style lang="scss">
.text-control-input.twn-input {
  @apply text-principal-gray flex-grow;
}

input.text-control-input.twn-input {
  height: 1.6rem;
}
textarea.text-control-input.twn-input {
  resize: none;
}

.text-control-label {
  color: $textLight;
  padding-right: 0.75rem;
  line-height: 1.6rem;
  vertical-align: middle;
}

.invalid {
  .text-control-label {
    @apply text-red;
  }

  .text-control-input.twn-input {
    @apply border-red;
  }
}
</style>

