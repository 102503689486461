<template>
  <div class="border-top flex flex-col">
    <div class="py-2">
      <ConversationReplyTo :replyTo="replyTo" v-if="replyTo" />

      <div class="flex items-end">
        <TextareaAutoResize placeholder="Entrez votre message" class="flex-grow" v-model="contentMessage" :maxSize="108" ref="form" @click.native="handleScrollTo" />
        <button type="button" class="twn-button text-xs ml-4" @click="handleSubmitMessage">Envoyer</button>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'

import TextareaAutoResize from "@/components/TextareaAutoResize"
import ConversationReplyTo from "@/components/conversation/ConversationReplyTo"

export default {
  components: {
    TextareaAutoResize,
    ConversationReplyTo
  },

  props: {
    conversationId: {
      type: String,
    },
  },

  data() {
    return {
      contentMessage: "",
    }
  },

  computed: {
    ...mapState('Conversation', [ 'replyTo' ]),
  },

  methods: {
    async handleSubmitMessage() {

      if(this.contentMessage.length > 0) {
        const { form } = this.$refs

        const response = await this.$store.dispatch('Conversation/saveMessage', {
          content: this.contentMessage,
          conversation_id: this.conversationId
        })

        if (response.id) {
          this.contentMessage = ""
          this.$store.commit('Conversation/SET_STATE', {
            replyTo: null,
          })
          form.resetSize()
        }
      }
    },

    handleScrollTo() {
      const element = document.querySelector('#conversationMessageList')
      element.scrollTop = element.scrollHeight
    }
  },
};
</script>
