import apollo from '@/apolloClient'

import {
  GC_GET_CONVERSATION_BY_ID,
  GC_ADD_MESSAGE_ONE,
  GC_SUBSCRIBE_CONVERSATION_BY_ID,
  GC_GET_STATUS_TYPE_LIST,
  GC_UPDATE_MESSAGE_BY_ID,
} from "@/graphql/conversation";

export default {
  namespaced: true,

  state: {
    conversation: null,
    replyTo: null,
    loading: true,
    statusTypeList: null,
  },

  mutations: {
    SET_STATE(state, data) {
      Object.assign(state, {
        ...data,
      });
    },
  },

  getters: {
    getStatusId: (state) => (slug) => {
      return state.statusTypeList.find((obj) => obj.slug === slug).id;
    },

    getStatusSlug: (state) => (id) => {
      return state.statusTypeList.find((obj) => obj.id === id).slug;
    },
  },

  actions: {
    async getStatusTypeList({ state, commit }) {
      if (state.statusTypeList) {
        return state.statusTypeList;
      }

      const response = await apollo.query({ query: GC_GET_STATUS_TYPE_LIST });

      commit("SET_STATE", {
        statusTypeList: response.data.conversation_message_status,
      });
    },

    getByID({ state, commit }, id) {
      commit("SET_STATE", {
        loading: true,
      });

      apollo
        .query({
          query: GC_GET_CONVERSATION_BY_ID,
          variables: {
            id,
          },
        })
        .then((response) => {
          commit("SET_STATE", {
            conversation: response.data.conversation_by_pk,
          });

          apollo
            .subscribe({
              query: GC_SUBSCRIBE_CONVERSATION_BY_ID,
              variables: {
                id,
              },
            })
            .subscribe({
              next(response) {
                // Force scroll to bottom if new message
                if (response.data.conversation_by_pk.messages.length > state.conversation.messages.length) {
                  // Scroll messages list to bottom
                  setTimeout(function() {
                    const element = document.querySelector(
                      "#conversationMessageList"
                    );
                    // Prevent no conversation (just in case)
                    if (element) {
                      element.scrollTop = element.scrollHeight;
                    }
                  }, 200);
                }

                commit("SET_STATE", {
                  conversation: response.data.conversation_by_pk,
                  loading: false,
                });
              },
            });
        });
    },

    async saveMessage({ state, dispatch, getters, rootState }, data) {
      let response = null;
      let result = {};

      if (data.id) {
        response = await apollo.mutate({
          mutation: GC_UPDATE_MESSAGE_BY_ID,
          variables: {
            id: data.id,
            message: data.message,
          },
        });

        result.id = response.data.update_conversation_message_by_pk.id;
      }       
      else {
        await dispatch("getStatusTypeList");

        const message = {
          conversation_id: data.conversation_id,
          user_id: rootState["Auth"].userInfo.id,
          status_id: getters.getStatusId("approved"),
          content: data.content,
          parent_id: state.replyTo ? state.replyTo.id : null,
        };

        response = await apollo.mutate({
          mutation: GC_ADD_MESSAGE_ONE,
          variables: {
            message,
          },
        });

        result.id = response.data.insert_conversation_message_one.id;
      }

      return result;
    },
  }
};