<template>
  <div class="message__parent-container flex flex-col" :class="listClassContainer">
    <div v-if="!isMe" :class="listClassUsername">{{ username }}</div>
    <div class="message__parent-content">{{ messageParent.content }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    messageParent: {
      type: Object,
    },
    childUserRole: {
      type: String,
    },
  },

  computed: {
    ...mapState('Auth', [ 'userInfo' ]),

    // Manage CSS Classes
    listClassContainer() {
      const color = this.isMe
        ? "message__parent-container--color-me"
        : this.isAdmin && this.childUserRole !== 'superadmin'
        ? "message__parent-container--color-admin"
        : this.isUser && this.childUserRole === 'user'
        ? "message__parent-container--color-white"
        : "message__parent-container--color-user";

      return color;
    },
    
    listClassUsername() {
      const color = this.isAdmin
        ? "message__parent-username--color-admin"
        : "message__parent-username--color-user";

      return color;
    },


    // Helpers
    isMe() {
      return this.messageParent.user.id === this.userInfo.id;
    },

    isAdmin() {
      return this.messageParent.user.role === "superadmin";
    },

    isUser() {
      return this.messageParent.user.role === "user";
    },


    // Concat username and role
    username() {
      const name = this.messageParent.user.name + " " + this.messageParent.user.first_name;
      const role = "Organisateur -";

      return this.isAdmin ? role + " " + name : name;
    },
  },
};
</script>

<style scoped>
.message__parent-container {
  @apply rounded-lg text-sm p-2 my-2 relative;
}

.message__parent-container--color-me {
  @apply bg-blue-200 text-principal-gray;
}

.message__parent-container--color-admin {
  @apply bg-orange-200 text-principal-gray;
}

.message__parent-container--color-white {
  @apply bg-white text-principal-gray;
}

.message__parent-container--color-user {
  @apply bg-gray-background text-principal-gray;
}

.message__parent-content {
  white-space: pre-line;
}

.message__parent-username--color-admin {
  @apply text-orange-700;
}

.message__parent-username--color-user {
  @apply text-principal-gray;
}
</style>
