<template>
  <div class="c-promotion-detail">
    <div class="buttons flex flex-row justify-around md:justify-between">
      <button type="button" class="twn-button text-xs" @click="goBack">
        Retour
      </button>
      <div>
        <button
          type="button"
          class="twn-button text-xs mr-4"
          @click="downloadStatistics()"
          v-if="itemID"
          :disabled="hasPendingStoreRequest"
        >
          Download CSV
        </button>
        <button
          type="button"
          class="twn-button text-xs danger mr-4"
          @click="showDeleteModal()"
          v-if="itemID"
          :disabled="hasPendingStoreRequest"
        >
          Supprimer la promotion
        </button>
        <button
          type="button"
          class="twn-button text-xs"
          @click="save"
          :disabled="hasPendingStoreRequest"
        >
          Enregistrer
        </button>
      </div>
    </div>

    <div class="twn-card my-10">
      <div class="flex">
        <div v-if="!itemID" class="uppercase font-principal-bold text-sm">
          Ajouter une promotion
        </div>
        <div v-else class="uppercase font-principal-bold text-sm">
          Éditer la promotion
        </div>
      </div>

      <div class="mt-8">
        <div class="flex">
          <div class="flex-1 mr-8">
            <label>Titre</label>
            <b-form-input v-model="promotion.title" type="text" autofocus/>
          </div>
          <div class="flex-1">
            <label>Parcours associé</label>
            <v-select
              label="name"
              placeholder="Parcours"
              v-model="promotion.course"
              :options="courseList"
              :filterable="true"
              :reduce="course => course.id"
            />
          </div>
        </div>

        <div class="flex mt-8">
          <div class="flex-1 mr-8">
            <label>Dates de session</label>
            <div class="flex items-center">
              <div class="mr-4">Du</div>
              <b-form-datepicker
                class="w-64 mr-4"
                v-model="promotion.startDate"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'long' }"
              ></b-form-datepicker>
              <div class="mr-4">Au</div>
              <b-form-datepicker
                class="w-64 mr-4"
                v-model="promotion.endDate"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'long' }"
                :min="promotion.startDate"
              ></b-form-datepicker>
            </div>
          </div>

          <div class="flex-1" v-if="typeList.length > 1">
            <label>Type</label>
            <v-select
              class="flex-1 mr-auto"
              label="name"
              placeholder="Type de promotion"
              v-model="promotion.type"
              :options="typeList"
              :reduce="type => type.id"
              :clearable="false"
            />
          </div>
        </div>

        <div class="mt-8" v-if="promotionType">
          <div class="tabs-triggers flex rounded border border-gray-light">
            <button
            v-if="hasSequencesSchedule"
            :class="{
              'selected': (currentTab == 'sequences')
            }"
            type="button"
            @click="currentTab = 'sequences'"
            >
              <span v-if="promotionType.slug == 'collective_promotion'">Dates de disponibilité des séquences</span>
              <span v-else-if="promotionType.slug == 'individual_promotion'">Dates limites de planification des séquences</span>
            </button>

            <button
            :class="{
              'selected': (currentTab == 'users')
            }"
            type="button"
            @click="currentTab = 'users'"
            >
              Stagiaires inscrits dans cette promotion
            </button>

            <button
            v-if="hasInternSchedule"
            :class="{
              'selected': (currentTab == 'calendar')
            }"
            type="button"
            @click="currentTab = 'calendar'"
            >
              <span v-if="promotionType.slug == 'collective_promotion'">Rattrapages</span>
              <span v-else-if="promotionType.slug == 'individual_promotion'">Calendrier des stagiaires</span>
            </button>
          </div>

          <PromotionSequenceForm
            v-show="currentTab == 'sequences'"
            :course="course"
            :promotion="promotion"
            :promotion-type="promotionType"
            @set-sequence-start="onSetSequenceStartDate"
            @set-sequence-end="onSetSequenceEndDate"
          />
          <PromotionInternsForm
            v-show="currentTab == 'users'"
            :promotion="promotion"
            :course="course"
            @add-intern="onAddIntern"
            @remove-intern="onRemoveIntern"
          />
          <PromotionInternSequenceForm
            v-show="currentTab == 'calendar'"
            :course="course"
            :promotion="promotion"
            :promotion-type="promotionType"
            @add-user-sequence="onAddInternSequence"
            @remove-user-sequence="onRemoveInternSequence"
          />
        </div>
      </div>
    </div>

    <!-- Modals -->
    <b-modal
      ref="delete-modal-detail-promotion"
      class="bootstrap"
      centered
      hide-footer
      id="delete-modal-detail-promotion"
      hide-header
    >
      <div class="d-block text-center my-6 uppercase font-semibold">
        <h3>Confirmer la suppression de "{{promotion.title}}"</h3>
      </div>
      <div class="flex flex-row justify-evenly items-center">
        <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal-detail-promotion')" >
          Retour
        </button>
        <button type="button" class="mt-4 twn-button danger" @click="onConfirmDelete">Supprimer</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import dispatchStoreRequest from "@/mixins/dispatchStoreRequest"
import { GC_GET_SCENARIO_NAME_BY_ID } from '@/graphql/scenario'
import PromotionSequenceForm from "./PromotionSequenceForm"
import PromotionInternsForm from "./PromotionInternsForm"
import PromotionInternSequenceForm from "./PromotionInternSequenceForm"
import * as XLSX from 'xlsx'

export default {
  props: [ 'itemID' ],
  mixins: [ dispatchStoreRequest ],
  components: {
    PromotionSequenceForm,
    PromotionInternsForm,
    PromotionInternSequenceForm
  },
  data: () => ({
    // UI State
    currentTab: 'users',
    // Local promotion data
    promotion: {
      id: null,
      title: '',
      startDate: new Date(),
      endDate: null,
      course: null,
      type: null,
      sequences: [],
      users: [],
    },
    course: null,
  }),
  computed: {
    ...mapState({
      courseList: state => state.Course.list,
      typeList: state => state.Promotion.typeList,
    }),
    promotionType() {
      if (!this.typeList || !this.promotion || !this.promotion.type)
        return null

      return this.typeList.find((type) => (type.id == this.promotion.type))
    },
    hasSequencesSchedule() {
      if (!this.promotionType)
        return false

      const validTypes = ['collective_promotion', 'individual_promotion']

      return (validTypes.indexOf(this.promotionType) > -1)
    },
    hasInternSchedule() {
      if (!this.promotionType)
        return false

      const validTypes = ['collective_promotion', 'individual_promotion']

      return (validTypes.indexOf(this.promotionType) > -1)
    },
  },
  watch: {
    itemID: {
      async handler(itemID) {
        if (itemID) {
          // todo: handle invalid uuid response
          await this.dispatchStoreRequest('Promotion/getByID', itemID, true)

          // Convert remote data to local one
          const remoteData = this.$store.state.Promotion.items[itemID]

          this.promotion.id = itemID

          // todo: handle error
          if (!remoteData)
            return

          this.promotion.title = remoteData.title
          this.promotion.startDate = remoteData.start_date
          this.promotion.endDate = remoteData.end_date
          this.promotion.course = remoteData.course_id
          this.promotion.scenarios = null //Set scenario var for log data
          this.promotion.type = remoteData.promotion_type_id
          this.promotion.sequences = remoteData.sequences.map((sequence) => {
            return {
              id: sequence.sequence_id,
              startDate: new Date(sequence.start_date),
              endDate: new Date(sequence.end_date),
              userID: sequence.user_id,
            }
          })
          this.promotion.users = remoteData.users.map((user) => {
            return {
              id: user.user_id,
            }
          })
        } else {
          // Clear local data
          this.promotion.id = null
          this.promotion.title = ''
          this.promotion.startDate = new Date()
          this.promotion.endDate = null
          this.promotion.course = null
          this.promotion.type = null
          this.promotion.sequences = []
          this.promotion.users = []
          this.promotion.scenarios = null

          if (!this.promotion.type && this.typeList && this.typeList.length > 0 && this.typeList[0].id) {
            this.promotion.type = this.typeList[0].id
          }
        }
      },
      immediate: true,
    },
    'promotion.startDate': {
      handler(startDate) {
        if (startDate && this.promotion.endDate && startDate > this.promotion.endDate) {
          this.promotion.endDate = this.promotion.startDate
        }
      },
      immediate: true,
    },
    'promotion.course': {
      async handler(courseID, oldCourseID) {
        if (courseID) {
          // Set temp data state
          this.course = {}
          
          // Load course data
          this.course = await this.dispatchStoreRequest('Course/GetCourse', courseID, true)

          // Reset sequences data if needed
          if (oldCourseID && courseID != oldCourseID) {
            this.promotion.sequences = []
          }
        } else {
          this.course = null
        }
      },
      immediate: true,
    },
    typeList(list) {
      // Set default values
      if (!this.promotion.type && list && list.length > 0 && list[0].id) {
        this.promotion.type = list[0].id
      }
    },
    'promotion.type': {
      handler(type) {
        // Set default values
        if (!type && this.typeList && this.typeList.length > 0 && this.typeList[0].id) {
          this.promotion.type = this.typeList[0].id
        }
      }
    },
  },
  async mounted() {
    // Load users, course and promotion type list
    await this.dispatchStoreRequest('Course/getList')
    await this.dispatchStoreRequest('Promotion/getTypeList')
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'promotions-list' })
    },
    showDeleteModal() {
      this.$refs['delete-modal-detail-promotion'].show()
    },
    async downloadStatistics(){
      let promotionData = await this.$store.dispatch('Logs/PromotionLogs', {
        ids:this.promotion.users,
        from: this.promotion.startDate,
        to: this.promotion.endDate
      })

      let wb = XLSX.utils.book_new();

      await this.formatPromotionStatistics(wb, promotionData);
      this.formatUserStatistics(wb, promotionData);

      let today = new Date();
      let name = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}-${today.getHours()}h${today.getMinutes()}-${today.getSeconds()}_data.xlsx`
      XLSX.writeFile(wb, name);
    },
    async formatPromotionStatistics(wb, promotionData){
      let sheet_Total = XLSX.utils.aoa_to_sheet([
        [this.promotion.title],
        ["Date de début", "Date de fin"],
        [this.promotion.startDate, this.promotion.endDate],
        [],
        ["Temps moyen des utilisateurs", "Temps cumulé des utilisateurs"],
        [promotionData.formatedAverageCoTime, promotionData.formatedAdditionalCoTime],
        [],
        ["Temps moyen par scénario"]
      ])

      let response = await this.$apollo.query({
        query: GC_GET_SCENARIO_NAME_BY_ID,
        variables: {
            ids: Object.keys(promotionData.averageScenarioTime)
        }
      })

      this.promotion.scenarios = response.data.scenario.reduce((dict, scenario) => {
        dict[scenario.id] = scenario.name
        return dict
      }, {})

      let index = 7;
      //Scenar name => temps
      for (const scenarioID in promotionData.averageScenarioTime) {
        if (Object.hasOwnProperty.call(promotionData.averageScenarioTime, scenarioID)) {
          const scenarioTime = promotionData.averageScenarioTime[scenarioID];
          
          XLSX.utils.sheet_add_aoa(sheet_Total, [
            [this.promotion.scenarios[scenarioID], scenarioTime]
          ], {origin:{r:index, c:0}})
          index++;
        }
      }

      // Get sheet name with excel limitation check
      let sheetName = this.promotion.title

      if (sheetName.length > 26) {
        sheetName = sheetName.substring(0, 26) + '...'
      }

      XLSX.utils.book_append_sheet(wb, sheet_Total, sheetName)
    },
    formatUserStatistics(wb, promotionData){
      for (const userName in promotionData.users) {
        if (Object.hasOwnProperty.call(promotionData.users, userName)) {
          const userData = promotionData.users[userName]

          let sheet_user = XLSX.utils.aoa_to_sheet([
            [(userData.firstName + " " + userName)],
            ["Temps de connexion moyen par jour"],
          ])
          let index = 1;

          for (const dayID in userData.connexionTime) {
            if (Object.hasOwnProperty.call(userData.connexionTime, dayID)) {
              const timePerDay = userData.connexionTime[dayID];
              XLSX.utils.sheet_add_aoa(sheet_user, [
                [dayID, timePerDay]
              ], {origin:{r:index, c:0}})
              index++;
            }
          }

          XLSX.utils.sheet_add_aoa(sheet_user, [
            [],
            ["Temps moyen par scénario"],
          ], {origin:{r:index, c:0}})
          index += 2

          for (const scenarioID in userData.formatedScenarioTimes) {
            if (Object.hasOwnProperty.call(userData.formatedScenarioTimes, scenarioID)) {
              const scenarioTime = userData.formatedScenarioTimes[scenarioID];
              XLSX.utils.sheet_add_aoa(sheet_user, [
                [this.promotion.scenarios[scenarioID], scenarioTime]
              ], {origin:{r:index, c:0}})
              index++;
            }
          }

          // Get sheet name with excel limitation check
          let sheetName = (userData.firstName + " " + userName)

          if (sheetName.length > 26) {
            sheetName = sheetName.substring(0, 26) + '...'
          }

          XLSX.utils.book_append_sheet(wb, sheet_user, sheetName)
        }
      }
    },
    onConfirmDelete(){
      this.$store.dispatch('Promotion/delete', this.itemID)
      this.$bvModal.hide('delete-modal-detail-promotion')
      this.goBack()
    },
    onSetSequenceStartDate({ sequenceID, startDate }) {
      // Try to find and update the sequence data
      for (var i = 0; i < this.promotion.sequences.length; i++) {
        if (this.promotion.sequences[i].userID == null && this.promotion.sequences[i].id == sequenceID) {
          this.promotion.sequences[i].startDate = startDate
          return
        }
      }
      
      // If no data exist, create a default one
      this.promotion.sequences.push({
        id: sequenceID,
        startDate,
        endDate: null,
        userID: null,
      })
    },
    onSetSequenceEndDate({ sequenceID, endDate }) {
      // Try to find and update the sequence data
      for (var i = 0; i < this.promotion.sequences.length; i++) {
        if (this.promotion.sequences[i].userID == null && this.promotion.sequences[i].id == sequenceID) {
          this.promotion.sequences[i].endDate = endDate
          return
        }
      }
      
      // If no data exist, create a default one
      this.promotion.sequences.push({
        id: sequenceID,
        startDate: null,
        endDate,
        userID: null,
      })
    },
    onAddIntern(intern) {
      this.promotion.users.push({ id: intern.id })
    },
    onRemoveIntern(intern) {
      for (var i = 0; i < this.promotion.users.length; i++) {
        if (this.promotion.users[i].id == intern.id) {
          this.promotion.users.splice(i, 1)
          break
        }
      }
    },
    onAddInternSequence(internSequence) {
      this.promotion.sequences.push({
        id: internSequence.id,
        startDate: internSequence.startDate,
        endDate: internSequence.endDate,
        userID: internSequence.userID,
      })
    },
    onRemoveInternSequence(internSequence) {
      for (var i = 0; i < this.promotion.sequences.length; i++) {
        if (this.promotion.sequences[i].id == internSequence.id && this.promotion.sequences[i].userID == internSequence.userID) {
          this.promotion.sequences.splice(i, 1)
          break
        }
      }
    },
    async save() {
      if (!this.promotion.title || this.promotion.title == '')
        return

      const response = await this.dispatchStoreRequest('Promotion/save', this.promotion)

      if (response.id) {
        this.$router.push({
          name: 'promotions-edit',
          params: {
            itemID: response.id,
          }
        })
      }

      this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })
    },
  },
}
</script>

<style lang="scss" scoped>;
.tabs-triggers {
  button {
    @apply flex-1 p-2 font-principal-medium;

    &.selected {
      @apply text-white bg-red;
    }
  }
}
</style>