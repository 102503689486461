import gql from 'graphql-tag'

const GC_GET_SCENARIO_TYPE_LIST = gql`
query GetScenarioTypeList {
    scenario_type {
      id
      name
      description
      slug
    }
  }
`

const GC_GET_SCENARIO_NAME_BY_ID = gql`
query GetScenarioNames($ids: [uuid!]!) {
  scenario (where: { id: { _in: $ids } } ){
    id
    name
    title
  }
}
`

const GC_GET_SCENARIO_LIST = gql`
query GetScenarioList {
    scenario {
      id
      name
      title
      description
      scenario_type_id
      type: scenarioType {
        slug
      }
    }
  }
`

const GC_GET_SCENARIO_BY_ID = gql`
query ScenarioByID($id: uuid!) {
  scenario(where: {id: {_eq: $id}}) {
    id
    name
    title
    description
    scenario_type_id
    spawn_point_id
    map_point_id
    preset_scenario_id
    linked_scenario_id
    correction_id
    revision_delay
    scenarioLocations {
      location_id
    }
    scenes {
      scene_id
    }
    nodes {
      id
      title
      node_content
      nodeType {
        slug
      }
      scenario_id
      position_x
      position_y
      triggers {
        name
        node_id
        signal_id
        triggered_node_id
        triggerType {
          slug
        }
      }
    }
    contentLogs(order_by: {created_at: desc}) {
      created_at
      user {
        name
        first_name
      }
    }
  }
}
`

const GC_ADD_SCENARIO_ONE = gql`
mutation AddScenario($name: String!, $title: String, $description: String!, $scenario_type_id: uuid!, $spawn_point_id: uuid, $map_point_id: uuid, $preset_scenario_id: uuid, $linked_scenario_id: uuid, $correction_id: uuid, $revision_delay: Int, $locations: [scenario_location_insert_input!]! = [], $scenes: [scenario_scene_insert_input!]! = [], $nodes: [node_insert_input!]! = []) {
  insert_scenario_one(object: {
    name: $name,
    description: $description,
    title: $title,
    scenario_type_id: $scenario_type_id,
    spawn_point_id: $spawn_point_id,
    map_point_id: $map_point_id,
    preset_scenario_id: $preset_scenario_id,
    linked_scenario_id: $linked_scenario_id,
    correction_id: $correction_id,
    revision_delay: $revision_delay,
    scenarioLocations: {
      data: $locations
    },
    scenes: {
      data: $scenes
    }
    nodes: { data: $nodes }
  }) {
    id
    nodes {
      id
    }
  }
}
`

/*
  1) Update scenario base data
  2) Delete scenario locations that no longer exist, insert newly added scenario locations
  3) Delete scenario scenes that no longer exist, insert newly added scenario scenes
  4) Delete nodes that no longer exist, insert newly added nodes
*/
const GC_UPDATE_SCENARIO_BY_ID = gql`
mutation UpdateScenario($id: uuid!, $name: String!, $title: String, $description: String!, $scenario_type_id: uuid!, $spawn_point_id: uuid, $map_point_id: uuid, $preset_scenario_id: uuid, $linked_scenario_id: uuid, $correction_id: uuid, $revision_delay: Int, $locations_ids: [uuid!]! = [], $new_locations: [scenario_location_insert_input!]! = [], $scenes_ids: [uuid!]! = [], $new_scenes: [scenario_scene_insert_input!]! = [], $nodes: [node_insert_input!]! = []) {
  update_scenario_by_pk(pk_columns: { id: $id }, _set: {
    name: $name,
    title: $title,
    description: $description,
    scenario_type_id: $scenario_type_id,
    spawn_point_id: $spawn_point_id,
    map_point_id: $map_point_id,
    preset_scenario_id: $preset_scenario_id,
    linked_scenario_id: $linked_scenario_id,
    correction_id: $correction_id,
    revision_delay: $revision_delay,
  }) {
    id
  }

  delete_scenario_location(where: {
    location_id: {_nin: $locations_ids},
    scenario_id: {_eq: $id}
  }) {
    affected_rows
  }
  insert_scenario_location(objects: $new_locations) {
    affected_rows
  }

  delete_scenario_scene(where: {
    scene_id: {_nin: $scenes_ids},
    scenario_id: {_eq: $id}
  }) {
    affected_rows
  }
  insert_scenario_scene(objects: $new_scenes) {
    affected_rows
  }

  delete_node(where: {
    scenario_id: {_eq: $id}
  }) {
    affected_rows
  }
  insert_node(objects: $nodes) {
    affected_rows
    returning {
      id
    }
  }
}
`

const GC_ADD_NODE_TRIGGERS = gql`
mutation AddTriggers($triggers: [trigger_insert_input!]! = []) {
  insert_trigger(objects: $triggers) {
    affected_rows
  }
}
`

export {
  GC_GET_SCENARIO_TYPE_LIST,
  GC_GET_SCENARIO_LIST,
  GC_GET_SCENARIO_BY_ID,
  GC_ADD_SCENARIO_ONE,
  GC_UPDATE_SCENARIO_BY_ID,
  GC_ADD_NODE_TRIGGERS,
  GC_GET_SCENARIO_NAME_BY_ID
}