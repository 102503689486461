import Rete from "rete";
import VueSelectControl from "./SelectControl.vue";

export class SelectControl extends Rete.Control {
  constructor(key, label, data_source, clearable = false, multiple = false) {
    super(key)
    this.component = VueSelectControl
    this.props = { ikey: key, label, data_source, clearable, multiple }
  }
}
