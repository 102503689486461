import Rete from "rete";
import { TextControl } from "../controls/TextControl.js";
import * as Socket from "../sockets";
import CustomNode from './CustomNode.vue';

export class ColorComponent extends Rete.Component {
  constructor() {
    super("Color");
    this.data = {
      render: 'vue',
      component: CustomNode
    }
  }

  builder(node) {
    var out1 = new Rete.Output("color", "Color", Socket.trigger);
    
    node.description = "Ajouter une vidéo, un son, une image"

    return node
      .addControl(new TextControl(this.editor, "color"))
      .addOutput(out1);
  }

  worker(node, inputs, outputs) {
    outputs["color"] = node.data.color;
  }
}
