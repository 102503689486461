<template>
    <textarea 
        :placeholder="placeholder" 
        @input="resize(); $emit('input', $event.target.value);" 
        :value="value" 
        ref="textarea"
        class="rounded"
    ></textarea>
</template>

<script>
export default ({
    props: {
        value: {
            type: String,
        },
        placeholder: {
            type: String,
            default: "",
        },
        maxSize: {
            type: Number,
            default: 86,
        },
    },

    methods: {
        resize() {
            const { textarea } = this.$refs;
            
            textarea.style.height = "43px";
            textarea.style.height = textarea.scrollHeight <= this.maxSize ? (textarea.scrollHeight + 2) + "px" : this.maxSize + "px";
        },

        resetSize() {
            const { textarea } = this.$refs;

            textarea.style.height = "43px";
        }
    }
})
</script>

<style scoped>
    textarea {
        @apply p-2 border text-sm;
        resize: none;
        height: 43px;
    }

    textarea:focus {
        outline: none;
    }
</style>