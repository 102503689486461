<template>
  <div class="mt-4">
    <div class="inline-block w-1/3 mb-4">
      <label>Ajouter un utilisateur</label>
      <v-select
      :options="internsList"
      :filterable="true"
      label="name"
      placeholder="Rechercher un utilisateur..."
      v-model="selectedUser"
      @input="onUserSelect($event)"
      />
    </div>

    <div class="inline-block w-2/3 text-right px-4">
      <span>{{ promotion.users.length }}</span>
      <span> utilisateurs</span>
    </div>

    <div class="border-line-left border rounded p-4">
      <div class="flex p-2 my-2 border-b border-gray-light font-bold">
        <p class="flex-1">Nom</p>
        <p class="flex-1">Statut</p>
        <p class="flex-1">Progression</p>
        <p class="w-8"></p>
      </div>

      <div
        v-for="intern in promotion.users"
        :key="intern.id"
        class="flex p-2 my-2 border-b border-gray-light"
      >
        <template v-if="interns[intern.id]">
          <p class="flex-1">{{interns[intern.id].name}}</p>
          <p class="flex-1">{{interns[intern.id].status}}</p>
          <p class="flex-1">{{interns[intern.id].progress}}</p>
        </template>

        <div
          class="flex justify-center text-xl font-bold items-center cursor-pointer col-span-1"
          @click="onRemoveInternClick(intern)"
        >
          <div class="border border-gray-light rounded-full text-center w-8 h-8">-</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  props: {
    promotion: {
      type: Object,
      required: true,
    },
    course: {
      type: Object,
      default: null,
    },
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
        
        // todo: improve "is focusable" check
        if (el.tagName != 'input') {
          el.querySelector('input').focus()
        }
      },
    }
  },
  data() {
    return {
      selectedUser: null,
      internsExtraData: null,
    }
  },
  computed: {
    ...mapState('Utils', ['userList']),
    internsList() {
      if (!this.userList)
        return []

      return this.userList.filter((user) => {
        return !this.promotion.users.some((intern) => (user.id == intern.id))
      }).map((user) => {
        return {
          id: user.id,
          name: ((user.first_name || '') + ' ' + (user.name || '')),
        }
      })
    },
    interns() {
      if (!this.userList)
        return {}

      return this.userList.reduce((dict, user) => {
        const extraData = this.internsExtraData?.[user.id]
        let status = '-'
        let progress = '-'

        if (extraData) {
          status = ((extraData.lastLog.length > 0) ? 'Actif' : 'Inactif')

          if (this.promotionScenarios.length > 0) {
            const scenarioDone = extraData.scenarioDoneLogs.filter((log) => {
              return (this.promotionScenarios.indexOf(log.data_key) > -1)
            })

            progress = (scenarioDone.length / this.promotionScenarios.length)
            progress = (progress.toFixed(2) * 100) + ' %'
          }
        }

        dict[user.id] = {
          name: [user.first_name, user.name].filter((s) => !!s).join(' '),
          status,
          progress,
        }

        return dict
      }, {})
    },
    promotionScenarios() {
      if (!this.course?.modules) {
        return []
      }

      // Get scenario list for this promotion course
      return this.course.modules.reduce((scenarios, mod) => {
        // Get scenarios in this module sequences
        mod.sequences?.forEach((sequence) => {
          scenarios = scenarios.concat(sequence.scenarios)
        })

        return scenarios
      }, []).map((orderedScenario) => orderedScenario.scenario.id)
    }
  },
  watch: {
    'promotion.users': {
      async handler() {
        // Load users extra data (stats mostly)
        const ids = this.promotion.users.map((user) => user.id)
        const userExtraData = await this.$store.dispatch('Promotion/getInternsExtraData', ids)

        this.internsExtraData = userExtraData.reduce((dict, user) => {
          dict[user.id] = user
          return dict
        }, {})
      },
      immediate: true,
    },
  },
  methods: {
    onUserSelect(intern) {
      this.$emit('add-intern', intern)

      this.$nextTick(() => this.selectedUser = null)
    },
    onRemoveInternClick(intern) {
      this.$emit('remove-intern', intern)
    },
    refreshUserList() {
      this.$store.dispatch('Utils/getUsers')
    }
  },
};
</script>