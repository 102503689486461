import gql from 'graphql-tag'

const GC_GET_USER_LIST = gql`
query GetUserList {
    users {
      id
      name
      first_name
      role
      experiences
    }
  }
`

const GC_GET_USER_BY_ID = gql`
query UserByID($id: uuid!) {
  users_by_pk(id: $id) {
    id
    name
    first_name
    email
    role
    gender
    age
    is_disabled_worker
    level_studies
    actual_position
    actual_working_time
    rise_working_time
    contract
    experiences
    has_professional_courses
    professional_courses
    has_distance_learning
    expectations
    additionnal_needs
    cognito_id
    last_log: logs(limit: 1) {
      id
    }
    content_logs(order_by: {created_at: desc}) {
      created_at
      user {
        name
        first_name
      }
    }
  }
}
`

const GC_ADD_USER_ONE = gql`
mutation AddUser(
    $name: String!,
    $first_name: String!,
    $email: String!,
    $role: String!,
    $gender: String!,
    $age: String!,
    $is_disabled_worker: Boolean,
    $level_studies: String!,
    $actual_position: String!,
    $actual_working_time: String!,
    $rise_working_time: Boolean,
    $contract: String!,
    $experiences: String!,
    $has_professional_courses: Boolean,
    $professional_courses: String!,
    $has_distance_learning: Boolean,
    $expectations: String!,
    $additionnal_needs: String!,
    $cognito_id: String!
  ) {
  insert_users_one(object: {
    name: $name,
    first_name: $first_name,
    email: $email,
    role: $role,
    gender: $gender,
    age: $age,
    is_disabled_worker: $is_disabled_worker,
    level_studies: $level_studies,
    actual_position: $actual_position,
    actual_working_time: $actual_working_time,
    rise_working_time: $rise_working_time,
    contract: $contract,
    experiences: $experiences,
    has_professional_courses: $has_professional_courses,
    professional_courses: $professional_courses,
    has_distance_learning: $has_distance_learning,
    expectations: $expectations,
    additionnal_needs: $additionnal_needs
    cognito_id: $cognito_id
  }) {
    id
  }
}
`

const GC_UPDATE_USER_BY_ID = gql`
mutation UpdateUser(
		$id: uuid!,
		$name: String!,
    $first_name: String!,
    $role: String!,
    $gender: String!,
    $age: String!,
    $is_disabled_worker: Boolean,
    $level_studies: String!,
    $actual_position: String!,
    $actual_working_time: String!,
    $rise_working_time: Boolean,
    $contract: String!,
    $experiences: String!,
    $has_professional_courses: Boolean,
    $professional_courses: String!,
    $has_distance_learning: Boolean,
    $expectations: String!,
    $additionnal_needs: String!
	) {
  update_users_by_pk(pk_columns: { id: $id },
  _set: {
    name: $name,
    first_name: $first_name,
    role: $role,
    gender: $gender,
    age: $age,
    is_disabled_worker: $is_disabled_worker,
    level_studies: $level_studies,
    actual_position: $actual_position,
    actual_working_time: $actual_working_time,
    rise_working_time: $rise_working_time,
    contract: $contract,
    experiences: $experiences,
    has_professional_courses: $has_professional_courses,
    professional_courses: $professional_courses,
    has_distance_learning: $has_distance_learning,
    expectations: $expectations,
    additionnal_needs: $additionnal_needs
  }) {
    id
    email
  }
}
`

const GC_UPDATE_USER_EMAIL_BY_ID = gql`
mutation UpdateUserEmail(
    $id: uuid!,
    $email: String!
  ) {
  update_users_by_pk(pk_columns: { id: $id },
  _set: {
    email: $email
  }) {
    id
  }
}
`

const GC_DELETE_USER_BY_ID = gql`
mutation DeleteUser($id: uuid!) {
  delete_users_by_pk(id: $id) {
    id
  }
}
`

export {
  GC_GET_USER_LIST,
  GC_GET_USER_BY_ID,
  GC_ADD_USER_ONE,
  GC_UPDATE_USER_BY_ID,
  GC_UPDATE_USER_EMAIL_BY_ID,
  GC_DELETE_USER_BY_ID,
}