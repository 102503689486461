<template>
  <div class="message__wrapper flex items-center" :class="listClassWrapper">
    <div class="message__container flex flex-col" :class="listClassContainer">
      <div v-if="!isMe && isFirstInSeries" class="self-end" :class="listClassUsername">
        {{ username }}
      </div>
      <div class="message__content">{{ message.content }}</div>

      <ConversationMessageParent :messageParent="message.parent" :childUserRole="message.user.role" v-if="message.parent" />

      <div class="flex text-xs mt-2">
        <div class="mr-5" v-if="statusTypeList">
          <div class="text-red cursor-pointer" @click="updateStatus('unapproved')" v-if="getStatusSlug(message.status_id) === 'approved'">Masquer</div>
          <div class="text-gray-500 cursor-pointer" @click="updateStatus('approved')" v-else-if="getStatusSlug(message.status_id) === 'unapproved'">Afficher</div>
        </div>
        <div class="text-gray-500 self-end">
          {{ dateFormatted }}
        </div>
      </div>
      <div class="message__triangle" :class="listClassTriangle" v-if="isLastInSeries"></div>
    </div>

    <div class="message__reply" v-if="!isMe" @click="setReplyTo(message)">
      <div class="rounded-full w-5 h-5 mr-2 bg-blue-200 flex-none flex items-center justify-center">
        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.93144 6.00637L2.36328 2.88806M2.36328 2.88806L6.03775 1.22729M2.36328 2.88806C4.16517 2.95839 8.1009 3.83377 9.42871 6.77269" stroke="white" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import ConversationMessageParent from "@/components/conversation/ConversationMessageParent";

export default {
  components: {
    ConversationMessageParent
  },

  props: {
    message: {
      type: Object,
    },
    previousMessageUserId: {
      type: String,
      default: null,
    },
    nextMessageUserId: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapState('Auth', [ 'userInfo' ]),
    ...mapState('Conversation', [ 'statusTypeList' ]),

    ...mapGetters('Conversation', [ 'getStatusId', 'getStatusSlug' ]),

    // Manage CSS Classes
    listClassWrapper() {
      const flex = this.isMe ? "message__wrapper--flex-me" : "";

      return flex;
    },
    
    listClassContainer() {
      const color = this.isMe
        ? "message__container--color-me"
        : this.isAdmin
        ? "message__container--color-admin"
        : "message__container--color-user";

      const margin =
        this.message.user.id === this.previousMessageUserId
          ? "message__container--margin-me"
          : this.isAdmin
          ? "message__container--margin-admin"
          : "message__container--margin-user";

      return color + " " + margin;
    },

    listClassUsername() {
      const color = this.isAdmin
        ? "message__username--color-admin"
        : "message__username--color-user";

      return color;
    },

    listClassTriangle() {
      const color = this.isMe
        ? "message__triangle--color-me"
        : this.isAdmin
        ? "message__triangle--color-admin"
        : "message__triangle--color-user";

      const direction = this.isMe
        ? "message__triangle--right"
        : "message__triangle--left";

      return color + " " + direction;
    },


    // Helpers
    isMe() {
      return this.message.user.id === this.userInfo.id;
    },

    isAdmin() {
      return this.message.user.role === "superadmin";
    },

    isLastInSeries() {
      return this.message.user.id !== this.nextMessageUserId;
    },

    isFirstInSeries() {
      return this.message.user.id !== this.previousMessageUserId;
    },


    // Concat username and role
    username() {
      const name = this.message.user.name + " " + this.message.user.first_name;
      const role = "Organisateur -";

      return this.isAdmin ? role + " " + name : name;
    },

    dateFormatted() {
      const date = new Date(this.message.created_at)

      return date.toLocaleString()
    },
  },


  mounted() {
    this.$store.dispatch("Conversation/getStatusTypeList")
  },

  methods: {
    setReplyTo(message) {
      this.$store.commit('Conversation/SET_STATE', {
        replyTo: message
      })
    },

    updateStatus(status) {
      this.$store.dispatch('Conversation/saveMessage', {
        id: this.message.id,
        message: {
          status_id: this.getStatusId(status),
        }
      })
    },
  }
}
</script>

<style scoped>
.message__wrapper--flex-me {
  @apply justify-end;
}

.message__container {
  @apply rounded-lg text-sm p-2 relative;
}

.message__container--color-me {
  @apply bg-blue-200 text-principal-gray;
}

.message__container--color-admin {
  @apply bg-orange-200 text-principal-gray;
}

.message__container--color-user {
  @apply bg-gray-background text-principal-gray;
}

.message__container--margin-me {
  margin-top: 2px;
}

.message__container--margin-admin {
  @apply mt-3;
}

.message__container--margin-user {
  @apply mt-2;
}

.message__content {
  white-space: pre-line;
}

.message__username--color-admin {
  @apply text-orange-700;
}

.message__username--color-user {
  @apply text-principal-gray;
}

.message__triangle {
  display: inline-block;
  height: 0;
  width: 0;
  position: absolute;
  bottom: 10px;
}

.message__triangle--left {
  left: -8px;
  border-top: 5px solid transparent !important;
  border-right: 8px solid;
  border-bottom: 3px solid transparent !important;
}

.message__triangle--right {
  right: -8px;
  border-top: 3px solid transparent !important;
  border-bottom: 5px solid transparent !important;
  border-left: 8px solid;
}

.message__triangle--color-me {
  @apply border-blue-200;
}

.message__triangle--color-admin {
  @apply border-orange-200;
}

.message__triangle--color-user {
  @apply border-gray-background;
}

.message__reply {
  @apply ml-4 mr-1 transition-opacity duration-100 ease-in;
  opacity: 1;
  cursor: pointer;
}

.message__reply:hover {
  opacity: 0.4;
}
</style>
