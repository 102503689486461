<template>
	<div class="course-detail">
		<div class="buttons flex flex-row justify-around md:justify-between">
			<button type="button" class="twn-button text-xs" @click="goBack">
				Retour
			</button>
			<div>
				<button
					type="button"
					class="twn-button text-xs danger mr-4"
					@click="showDeleteModal"
					v-if="!creating"
				>
					Supprimer le parcours
				</button>
				<button
					class="twn-button text-xs"
					@click="save"
				>
					Enregistrer
				</button>
			</div>
		</div>

		<div class="my-10">
			<div class="flex">
				<div v-if="creating" class="uppercase font-principal-bold text-sm">
					Création d'un nouveau parcours
				</div>
				<div v-else class="uppercase font-principal-bold text-sm">
					Éditer le parcours
				</div>
			</div>

			<div class="mt-8">
				<div class="flex items-center">
					<div class="flex-1 mr-8">
						<label>Nom du parcours</label>
						<b-form-input v-model="fields.name" type="text" />
					</div>

					<div class="flex-1 mr-8">
						<label class="mr-8">Type de parcours</label>
						<v-select
							class="flex-1 mr-auto"
							label="name"
							:options="types"
							placeholder="Type du parcours"
							v-model="fields.type"
							@input="changeType"
							:reduce="type => type.id"
							:selectable="type => type.slug != 'custom_course'"
						/>
					</div>
				</div>

				<div class="mt-8 module-container" v-if="courseType">
					<!-- module choice -->
					<div v-if="courseType != 'bloc_course' && fields.modules[activeModule]">
						<div class="module-selector justify-around rounded border border-gray-light">
							<div
								v-for="(module, i) in fields.modules"
								:key="i"
								class="module-select-container cursor-pointer"
								:class="{'active': activeModule == i}"
								@click="activeModule = i"
							>
								<div>
									{{ (courseType == 'complete_course' ? 'Niveau' : 'Module') }} {{ module.order + 1 }}
								</div>
							</div>
							<button class="py-2 px-6 font-principal-bold text-xl border-l border-l-gray-light" @click="addModule">+</button>
						</div>

						<div class="flex flex-row w-full my-4">
							<div class="w-1/4 pr-8">
								<label for="module-name">Identifiant</label>
								<input type="text" id="module-name" class="d-block twn-input w-full" v-model="fields.modules[activeModule].name">
							</div>
							<div class="w-3/4">
								<label for="module-title">Titre</label>
								<input type="text" id="module-title" class="d-block twn-input w-full" v-model="fields.modules[activeModule].title">
							</div>
						</div>

						<div v-if="courseType == 'complete_course'" class="w-full">
							<label for="module-scenario" class="">Scénarios disponible dans ce niveau</label>
							<v-select
								id="module-scenario"
								:options="scenarioOptions"
								@search="fetchScenariosByName"
								multiple
								label="name"
								class=""
								v-model="fields.modules[activeModule].scenarios"
							>
							</v-select>
						</div>


						<button class="twn-button danger py-2 my-4 ml-auto block" @click="showDeleteModuleModal">Supprimer le {{ (courseType == 'complete_course' ? 'niveau' : 'module') }}</button>
					</div>

					<span class="font-principal-medium">
						Séquences associées
					</span>
					<div
						v-if="
							fields.modules[activeModule].sequences && fields.modules[activeModule].sequences.length > 0
						"
					>
						<draggable v-model="fields.modules[activeModule].sequences" @input="changeSequenceOrder">
							<SequenceForm
								v-for="(sequence, j) in fields.modules[activeModule].sequences"
								:key="j"
								:sequence="sequence"
								:course-type="courseType"
								:scenario-list="scenarioList"
								:media-list="mediaList"
								@remove-sequence="removeSequence(j)"
								@add-sequence="addSequence(j)"
							/>
						</draggable>
					</div>
					<div v-else></div>
				</div>
			</div>
		</div>

		<!-- Modals -->
		<b-modal
			ref="delete-modal-course"
			class="bootstrap"
			centered
			hide-footer
			id="delete-modal-course"
			hide-header
		>
			<div class="d-block text-center my-6 uppercase font-semibold">
				<h3>Confirmer la suppression {{isDeleteModule ? "du " + (courseType == 'complete_course' ? 'niveau' : 'module') : ""}}</h3>
			</div>
			<div class="flex flex-row justify-evenly items-center">
				<button type="button" class="mt-4 twn-button" @click="hideModal" >
					Retour
				</button>
				<button type="button" class="mt-4 twn-button danger" @click="confirmDelete">Supprimer</button>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import { debounce } from "@/utils/utils"
import Draggable from "vuedraggable"
import {SEARCH_SCENARIO} from '@/graphql/course'

import SequenceForm from "@/components/common/course/SequenceForm"

export default {
    props: {
        itemID: {
            type: String,
            required: false
        },
        previousPage: {
            type: Number,
            required: false
        }
    },
	components: {
		Draggable,
		SequenceForm
	},
	data(){
		return{
			creating: false,
			isDeleteModule: false,
			nameTaken: false,
			fields: {
				id: null,
				name: null,
				type: null,
				modules: [
					{
						id: null,
						name: "",
						title: "",
						order: 0,
						scenarios: [],
						sequences: [
							{
								name: "",
								title: "",
								module_scenario_limit: 3,
								order: 0,
								scenarios: [],
								revisions: []
							}
						]
					}
				]
			},
			activeModule: 0,
			scenarioOptions: [],
			revisionOptions: [],
		}
	},

	computed: {
		...mapGetters("EditItem", ["getCurrentID"]),
		...mapState("Course", ["types"]),
		...mapState({
			scenarioList: state => state.Scenarios.list,
			mediaList: state => state.Media.list
		}),
		...mapGetters("Course", ["typeListFormat"]), 
		courseType() {
			if (!this.typeListFormat || !this.fields.type || !this.typeListFormat[this.fields.type])
				return null

			return this.typeListFormat[this.fields.type].slug
		},
	},

	created() {
		if (this.getCurrentID === -1 && !this.itemID) {
			this.creating = true
		}
		this.$store.dispatch("Course/GetCourseTypes")
		this.$store.dispatch("Scenarios/getList")
		this.$store.dispatch("Media/getList")
		this.getScenario("", false)
		this.getScenario("", true)

		if (this.itemID){
			this.mapFields()
		}
	},

	methods: {
		confirmDelete(){
			if (this.isDeleteModule){
				this.deleteModule()
			} else {
				this.$store.dispatch("Course/Delete", this.itemID)
				this.goBack()
			}

			this.hideModal()
		},
		fetchScenariosByName(name, loading) {
			loading(true)
			this.searchScenario(loading, name, false, this)
		},

		fetchRevisionByName(name, loading) {
			loading(true)
			this.searchScenario(loading, name, true, this)
		},

		searchScenario: debounce((loading, name, isRevision, vm) => {
			vm.getScenario(name, isRevision).then(() => loading(false));
		}, 350),

		getScenario(name, isRevision){
			return new Promise((resolve) => {
				this.$apollo.query({
					query: SEARCH_SCENARIO,
					variables: {
						name: `%${name}%`,
						slugs: isRevision ? ['revision'] : ['scenario', 'edl'] //todo: not revision instead of only scenario
					}
				}).then((res) => {
					if (res.data){
						if (isRevision){
							this.revisionOptions = res.data.scenario
							resolve()
						}else{
							this.scenarioOptions = res.data.scenario
							resolve()
						}
					}
				})
			})
		},

		goBack() {
			this.$router.push({name:'course-list', params: {
				previousPage: this.previousPage
			}})
		},

		async mapFields(){
			const fields = await this.$store.dispatch("Course/GetCourse", this.itemID)
			fields.modules = fields.modules.map(module => {
				let moduleret = module

				moduleret.scenarios = module.scenarios.map(sc => {
					return {
						id: sc.scenario.id,
						name: sc.scenario.name
					}
				})
				moduleret.sequences = module.sequences.map(sequence => {
					let sequenceret = sequence;

					const scenarios = sequence.scenarios.map(sc => {
						return {
							id:sc.scenario.id,
							name:sc.scenario.name,
							order: sc.order,
							type: sc.scenario.type,
						}
					}).sort((a, b) => {
						return (a.order > b.order ? 1 : (a.order < b.order ? -1 : 0))
					})

					sequenceret.scenarios = scenarios.filter((sc) => {
						return (sc.type.slug != 'revision')
					})

					sequenceret.revisions = scenarios.filter((sc) => {
						return (sc.type.slug == 'revision')
					})

					sequenceret.medias = sequence.medias.map(assoc => {
						return {
							id: assoc.media.id,
							name: assoc.media.name,
							order: assoc.order,
							type: assoc.media.type,
						}
					}).sort((a, b) => {
						return (a.order > b.order ? 1 : (a.order < b.order ? -1 : 0))
					})

					return sequenceret
				})
				return moduleret;
			})

			this.fields = fields

			this.changeType(this.fields.type);
		},

		showDeleteModal() {
			this.$refs["delete-modal-course"].show()
		},
		showDeleteModuleModal(){
			this.isDeleteModule = true
			this.$refs["delete-modal-course"].show()
		},
		removeSequence(index) {
			//remove
			if (this.fields.modules[this.activeModule].sequences.length > 1){
				this.fields.modules[this.activeModule].sequences.splice(index, 1)
			}

			this.fields.modules[this.activeModule].sequences.forEach((sequence, i) => {
				if (i >= index){
					//
					sequence.order--
				}
			})
		},
		addSequence(index) {
			//Add
			this.fields.modules[this.activeModule].sequences.splice(index + 1, 0, {
				id: null,
				name: "",
				module_scenario_limit: 2,
				order: index + 1,
				scenarios: [],
				revisions: []
			})
		},
		changeType(typeID) {
			if (this.typeListFormat[typeID] && this.typeListFormat[typeID].slug == "bloc_course") {
				this.activeModule = 0
			}
		},
		addModule(){
			this.fields.modules.push(this.defaultModule(this.fields.modules.length))
			this.activeModule = this.fields.modules.length - 1
		},
		defaultModule(order = 0){
			return {
				id: null,
				name: "",
				title: "",
				scenarios: [],
				order,
				sequences: [
					{
						name: "",
						title: "",
						order: 0,
						module_scenario_limit: 2,
						scenarios: [],
						revisions: [],
						medias: [],
					}
				]
			}
		},
		deleteModule(){
			if (this.fields.modules.length > 1){
				this.fields.modules.splice(this.activeModule, 1)
				this.fields.modules.forEach((module, i) => {
					if (i >= this.activeModule){
						module.order--
					}
				})
				if (this.activeModule - 1 >= 0){
					this.activeModule -= 1
				}

				this.$forceUpdate();
			}
		},
		async save(){
			if (this.fields.name && this.fields.name !== "") {
				const response = await this.$store.dispatch('Course/saveCourse', this.fields)
				if (response && response.error){
					this.$bvToast.toast('Une erreur est survenue, l\'un des champs requis doit être manquant', { title: `Erreur` })
					return;
				}

				if (response == false){
					this.nameTaken = true
					this.$bvToast.toast('Le nom est déjà pris !', { title: `Erreur` })
					return;
				}

				if (this.creating && response.id){
					this.fields.id = response.id
					this.creating = false
					this.$router.push({
						name: 'course-edit',
						params: {
							itemID: response.id,
							previousPage: this.previousPage
						}
					})
				}

				this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })
			}
		},
		changeSequenceOrder(sequences){
			this.fields.modules[this.activeModule].sequences = sequences.map((sq, i) => {
				let sequence = sq
				sequence.order = i
				return sequence
			})
		},
		hideModal(){
			this.$bvModal.hide('delete-modal-course')
			this.isDeleteModule = false
		}
	}
}
</script>
<style lang="scss" scoped>
.course-detail {
	.shadowed {
		box-shadow: $cardShadow;
	}
	.module-container{
		// border: 1px solid #DC9799;
		box-shadow: $cardShadow;
		padding: 1rem;
	}
	.module-selector{
		@apply flex flex-no-wrap flex-row mb-8;
	}
	.module-select-container{
		@apply w-full font-principal-medium flex justify-center items-center;

		&.active{
			@apply bg-principal-selected text-white;
		}
	}
	// .content{
	//     border-top: 1px solid $textMainColor;
	//     border-bottom: 1px solid $textMainColor;
	// }
}
</style>
