<template>
    <div class="game-simple">
        <Draggable v-model="questions" handle=".handle" v-bind="dragOptions">
            <div class="" v-for="(question, i) in questions" :key="i">
                <QuestionSimple
                :question="question"
                :number="i"
                class="my-5"
                :options="questionOptions"
                :mediaLoaded="mediaLoaded"
                @delete-question="deleteQuestion"
                @update-question="updateQuestion"
                @open-panel="openPanel(i)"
                @remove-media="removeQuestionMedia(i)">
                </QuestionSimple>
            </div>
        </Draggable>
        <div @click="addQuestion" class="plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl ">+</div>

        <RightPanel ref="rightPanel" title="Choix de média">
            <Medias
            :select-only="true"
            :draggable-asset="false"
            :default-type-filter-slug="['file_video']"
            @media-loaded="onMedialoaded"
            @select-media="onMediaSelected"></Medias>
        </RightPanel>
    </div>
</template>
<script>

import Draggable from 'vuedraggable'
import QuestionSimple from '@/components/Games/Question_simple'
import RightPanel from '@/components/RightPanel'
import Medias from "@/views/Medias"

export default {
    name: 'QuizSimple',
    components: {
        QuestionSimple,
        Draggable,
        RightPanel,
        Medias
    },
    props: {
		game: {
			type: Object,
			required: true,
			default: null
		}
    },
    data(){
        return {
            mediaLoaded: false,
            questionOptions: { hasMedia: true },
            panelQuestionIndex: null,
        }
    },
    computed: {
        questions:{
            get() {
                if (!this.game)
                    return []
                return JSON.parse(JSON.stringify(this.game.questions));
            },
            set(value){
                this.$emit('update-all-questions', value)
            }
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    },
    methods:{
        addQuestion(){
            let question = {
                answers:[{
                    text: '',
                    is_correct: false,
                }],
                text: '',
                correction: '',
                media_id: null,
            }
            this.$emit('add-questions', [question])
        },
        updateQuestion(i, data){
            this.$emit('update-question', i, data)
        },
        deleteQuestion(i){
            this.$emit('delete-questions', [i])
        },
        openPanel(index) {
            this.panelQuestionIndex = index
            this.$refs['rightPanel'].show()
        },
        removeQuestionMedia(index) {
            this.updateQuestion(index, { media_id: null })
        },
        onMedialoaded() {
            this.mediaLoaded = true
        },
        onMediaSelected(asset) {
            if (this.panelQuestionIndex === null || this.panelQuestionIndex === undefined) {
                return
            }

            this.updateQuestion(this.panelQuestionIndex, { media_id: asset.id })

            this.$refs['rightPanel'].hide()
            this.panelQuestionIndex = null
        },
    }
}
</script>