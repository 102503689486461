<template>
  <div class="asset-control flex" :class="{ 'flex-col': dropZone, 'highlighted': highlighted }">
    <!-- Label -->
    <span v-if="label" class="asset-label">{{label}}</span>

    <!-- Drop zone or button -->
    <div v-if="!asset" class="asset-chooser">
      <div
      v-if="dropZone"
      class="asset-drop-zone cursor-pointer"
      @pointerdown.stop="selectAsset"
      @drop.prevent.stop="dropAsset">
        <p>Sélectionner ou déposer un fichier depuis la bibliothèque de média</p>
      </div>

      <button 
      v-else
      class="twn-button text-xs"
      type="button"
      @pointerdown.stop="selectAsset"
      >Choisir</button>
    </div>

    <!-- Preview -->
    <div v-else class="asset-preview cursor-pointer" @pointerdown.stop="selectAsset" @drop.prevent.stop="dropAsset">
      <div v-if="asset.type.slug == 'file_image'" class="asset-preview-image">
        <img :src="asset.thumbnail_url" draggable="false">
      </div>

      <div v-else class="asset-preview-text">
        <span>{{asset.name}}</span>
        <span class="button-edit">
          <img src="@/assets/images/EDIT.svg">
        </span>
      </div>
    </div>
  </div>
</template>


<script>
  import store from "../../store"

  export default {
    props: {
      ikey: String,
      label: String,// todo:improve
      restrictTypes: Array,// todo:improve
      dropZone: Boolean, // Wheither or not to use a drop zone instead of a button to choose the asset
      getData: Function,
      putData: Function,
    },
    data() {
      return {
        asset: null,
        highlighted: false,
      }
    },
    watch: {
      asset: {
        handler(val, oldVal) {
          if (val == oldVal)
            return

          // Use custom event to make them available outside of the current Vue.js context
          this.$el.dispatchEvent(new CustomEvent('content-update', { bubbles: true, composed: true }))
        },
        immediate: true,
      }
    },
    methods: {
      selectAsset() {
        // Use custom event to make them available outside of the current Vue.js context
        this.$el.dispatchEvent(new CustomEvent('select-asset', { bubbles: true, composed: true, detail: {
          key: this.ikey,
          restrictTypes: this.restrictTypes
        } }))
        this.$el.dispatchEvent(new CustomEvent('select-asset-' + this.ikey, { bubbles: true, composed: true, detail: {
          key: this.ikey,
          restrictTypes: this.restrictTypes
        } }))
      },
      dropAsset(e) {
        const assetJSON = (e.dataTransfer ? e.dataTransfer.getData("application/json") : e.detail)
        if (assetJSON) {
          const asset = (assetJSON ? JSON.parse(assetJSON) : null)

          // Check asset type
          let validType = (!this.restrictTypes || this.restrictTypes.length <= 0)

          if (!validType && asset.type) {
            for (var i = 0; i < this.restrictTypes.length; i++) {
              if (asset.type.slug == this.restrictTypes[i]) {
                validType = true
                break
              }
            }
          }

          if (!validType) {
            return
          }        

          if (asset) {
            this.asset = asset

            if (this.ikey) {
              this.putData(this.ikey, this.asset.id)
              this.putData(this.ikey + '_asset', asset)
            }
          }

          // Use custom event to make them available outside of the current Vue.js context
          this.$el.dispatchEvent(new CustomEvent('asset-selected', { bubbles: true, composed: true }))
        }
      },
      setSelected(e) {
        if (e.detail !== false && e.detail !== true)
          return

        this.highlighted = e.detail
      },
    },
    mounted() {
      const id = this.getData(this.ikey)

      if (id && store.state.Assets.assets && store.state.Assets.assets.length > 0) {
        const asset = store.state.Assets.assets.find((asset) => (asset.id == id))
        
        if (asset) {
          this.putData(this.ikey + '_asset', asset)
          this.asset = asset
        }
      }

      this.$el.addEventListener('set-selected', this.setSelected)
      this.$el.addEventListener('set-asset', this.dropAsset)
    },
    beforeDestroy() {
      this.$el.removeEventListener('set-selected', this.setSelected)
      this.$el.removeEventListener('set-asset', this.dropAsset)
    },
  }
</script>


<style lang="scss">
.asset-control {
  .twn-button[type="button"] {
    min-width: auto;
    padding: 0.4rem 1.2rem;
  }
}

.asset-drop-zone {
  @apply text-xs;
  position: relative;
  display: flex;
  border: 2px dashed #dddddd;
  border-radius: 5px;
  padding: 40px 30px;
  text-align: center;

  p {
    max-width: 210px;
  }
}

.asset-label {
  color: $textLight;
  min-width: 50px;
  line-height: 34px;
  vertical-align: middle;
}

.asset-preview {
  @apply text-principal-gray;
  line-height: 34px;

  .asset-preview-image > img {
    @apply rounded border;
    margin: auto;
    max-height: 120px;
    max-width: 160px;
    width: auto;
  }

  .button-edit {
    img {
      display: inline-block;
      margin-left: 8px;
      height: 16px;
    }
  }
}

.highlighted {
  .twn-button[type="button"], .asset-drop-zone, .asset-preview, .asset-preview-image img {
    @apply text-red border-red;
  }
}
</style>
