import Rete from "rete"
import axios from 'axios'

import CustomNode from './CustomNode.vue'
import * as Socket from "../sockets"

import { AssetControl } from "../controls/AssetControl.js"

export class InteractiveComponent extends Rete.Component {
  constructor(node_type) {
    // Use the node type slug as component name
    super(node_type.slug)

    this.data = {
      render: 'vue',
      component: CustomNode,
      props: {
        title: node_type.name,
        description: node_type.description,
        controlContentUpdated: this.controlContentUpdated.bind(this),
      }
    }
  }

  builder(node) {
    const next = new Rete.Output("next", "Noeud suivant", Socket.trigger, false)//todo: comon ?
    const previous = new Rete.Input("previous", "", Socket.triggered, true)
    const interactiveAsset = new AssetControl("interactive", null, ['asset_interactive'], true)

    node
      .addInput(previous)
      .addControl(interactiveAsset)
      .addOutput(next)

    // Handle dynamic outputs
    if (node.data && node.data.customTriggers) {
      for (var i = node.data.customTriggers.length - 1; i >= 0; i--) {
        const trigger = node.data.customTriggers[i]

        if (trigger.name.substring(0, 8) == 'faa_int_') {
          // Add the coresponding new output to the node
          const intOutput = new Rete.Output(trigger.name, trigger.name.replace('faa_int_', ''), Socket.trigger, false)

          node.addOutput(intOutput)
        }
      }
    }
    
    return node
  }

  async controlContentUpdated(control, node, editor) {
    if (control.key == 'interactive') {
      let outputsKeyMap = {}

      if (node.data.interactive_asset && node.data.interactive_asset.url) {
        outputsKeyMap = await this.loadInteractiveContentOutputs(node, editor)
      }

      // Cleanup interactive outputs no longer needed from this node
      this.cleanupInteractiveOutputs(outputsKeyMap, node, editor)

      return true
    }

    return false
  }

  cleanupInteractiveOutputs(validOutputsKeyMap, node, editor) {
    node.outputs.forEach((output, key) => {
      if (key.substring(0, 8) == 'faa_int_' && !validOutputsKeyMap[key]) {
        // Remove outputs connections (must be done via editor.removeConnection because it's not done in removeOutput...)
        output.connections.slice().map(editor.removeConnection.bind(editor));

        // Remove output
        node.removeOutput(output)
      }
    })
  }

  async loadInteractiveContentOutputs(node, editor) {
    let outputsKeyMap = {}

    // Load SVG content
    await axios(node.data.interactive_asset.url).then((response) => {
      // Parse SVG
      if (response.data) {
        const parser = new DOMParser()
        const svg = parser.parseFromString(response.data, 'image/svg+xml')

        // Get interactive svg groups/element
        let container = svg.getElementById('faa-int')

        // Try another potential container
        if (!container) {
          container = svg.getElementById('faa-int-overlay')
        }

        if (container) {
          for (var i = 0; i < container.childNodes.length; i++) {
            const child = container.childNodes[i]

            // Skip invalid (non targetable) child
            if (!child.id)
              continue

            // Add the coresponding new output to the node, if needed
            const key = 'faa_int_' + child.id
            const existingOutput = node.outputs.get(key)

            if (existingOutput) {
              // Restore output order
              node.outputs.delete(existingOutput.key)
              node.outputs.set(existingOutput.key, existingOutput)
            } else {
              const intOutput = new Rete.Output(key, child.id, Socket.trigger, false)

              node.addOutput(intOutput)
            }

            // Add to map
            outputsKeyMap[key] = true            
          }
        }
      }
    })

    return outputsKeyMap
  }
}
