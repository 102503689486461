<template>
    <div class="mt-8 md:mt-0" v-if="!loading && conversation">
        <div class="flex flex-col">
            <div class="buttons flex flex-row justify-between">
                <router-link class="twn-button text-xs" :to="{ name: 'conversation-list' }">
                    Retour
                </router-link>
            </div>

            <div class="twn-card my-10 max-h-screen flex justify-between flex-col">
                <ConversationMessageList :messages="conversation.messages" />

                <ConversationMessageForm :conversationId="conversation.id" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import ConversationMessageList from "@/components/conversation/ConversationMessageList";
import ConversationMessageForm from "@/components/conversation/ConversationMessageForm";

export default {
    name: 'ConversationDetail',

    components: {
        ConversationMessageList,
        ConversationMessageForm,
    },
    
    props: {
        itemID: {
            type: String,
        },
    },
    
    computed: {
        ...mapState('Conversation', [ 'conversation', 'loading' ]),
    },

    mounted() {
        this.$store.dispatch("Conversation/getByID", this.itemID)
    },
}
</script>

<style lang="scss">
</style>