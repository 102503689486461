import gql from 'graphql-tag'

const CG_GET_ORGAS = gql`
query GetOrgas{
  organisations {
    id
    name
  }
}`

// const CG_GET_STATUS = gql`
// query GetStatus{
//     users_aggregate(distinct_on: experienced) {
//         nodes {
//             experienced
//         }
//     }
// }`

const GC_GET_USERS = gql`
query GetUsers{
    users {
      id
      name
      first_name
      email
      role
      created_at
      updated_at
      userOrganisations{
        organisations{
          name
        }
      }
    }
  }`

const GC_GET_FEREDATIONS = gql`
query GetFederations{
  federations{
    id
    name
    slug
  }
}
`

export {
    CG_GET_ORGAS,
    // CG_GET_STATUS,
    GC_GET_USERS,
    GC_GET_FEREDATIONS
}