import gql from "graphql-tag"

const GC_GET_STATUS_TYPE_LIST = gql`
  query GetStatusTypes {
    conversation_message_status {
      id
      name
      slug
    }
  }
`;

const GC_GET_CONVERSATION_BY_ID = gql`
  query GetConversation($id: uuid!) {
    conversation_by_pk(id: $id) {
      id
      messages(order_by: { created_at: asc }) {
        id
        created_at
        updated_at
        content
        status_id

        parent {
          id
          created_at
          updated_at
          content
          status_id

          user {
            id
            first_name
            name
            role
          }
        }

        user {
          id
          first_name
          name
          role
        }
      }
    }
  }
`;

const GC_SUBSCRIBE_CONVERSATION_BY_ID = gql`
  subscription conversationSubscription($id: uuid!) {
    conversation_by_pk(id: $id) {
      id
      messages(order_by: { created_at: asc }) {
        id
        created_at
        updated_at
        content
        status_id

        parent {
          id
          created_at
          updated_at
          content
          status_id

          user {
            id
            first_name
            name
            role
          }
        }

        user {
          id
          first_name
          name
          role
        }
      }
    }
  }
`;

const GC_ADD_MESSAGE_ONE = gql`
  mutation AddMessage($message: conversation_message_insert_input!) {
    insert_conversation_message_one(object: $message) {
      id
    }
  }
`;

const GC_UPDATE_MESSAGE_BY_ID = gql`
  mutation UpdateMessage($id: uuid!, $message: conversation_message_set_input!) {
    update_conversation_message_by_pk(pk_columns: { id: $id }, _set: $message) {
      id
    }
  }
`;

export {
  GC_GET_CONVERSATION_BY_ID,
  GC_ADD_MESSAGE_ONE,
  GC_SUBSCRIBE_CONVERSATION_BY_ID,
  GC_GET_STATUS_TYPE_LIST,
  GC_UPDATE_MESSAGE_BY_ID,
};