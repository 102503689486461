<template>
<div class="socket" :class="[type, socket.name] | kebab" :title="socket.name"></div>
</template>

<script>
import VueRender from 'rete-vue-render-plugin';

export default {
  extends: VueRender.Socket,
}
</script>

<style lang="scss" scoped>
$socket-size: 24px;
$socket-margin: 0;
$socket-color: #d8d8d8;

.socket {
  position: relative;
  display: inline-block;
  cursor: pointer;
  border: none;
  border-radius: $socket-size/2.0;
  width: $socket-size;
  height: $socket-size;
  margin: $socket-margin;
  vertical-align: middle;
  background: $socket-color;
  z-index: 2;
  box-sizing: border-box;

  &:hover {
    border-width: 4px;
  }

  &.multiple {
    border-color: yellow;
  }

  &.output {
    text-align: right;
  }

  &.input {
    margin-left: - $socket-size / 2;
  }
}
</style>
