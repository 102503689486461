export function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        }, wait);
        if (immediate && !timeout) func.apply(context, args);
    };
  }

export function parseJWT(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

// TODO: Don't store user with a refresh token because we can use the refresh token cookie to auth between refresh/tabs ?
export const userStorage = {
    storageKey: 'user',

    get() {
        const userJSON = localStorage.getItem(this.storageKey)

        if (!userJSON)
            return null

        try {
            const user = JSON.parse(userJSON)

            return user
        } catch (error) {
            return null
        }
    }, 

    set(user) {
        localStorage.setItem(this.storageKey, JSON.stringify(user))
    }, 

    clear() {
        localStorage.clear()
    }
}