import Rete from "rete"

import CustomNode from './CustomNode.vue'
import * as Socket from "../sockets"

import { AssetControl } from "../controls/AssetControl.js"
import { TextControl } from "../controls/TextControl.js"
import { SelectControl } from "../controls/SelectControl.js"

export class VideoPartComponent extends Rete.Component {
  constructor(node_type) {
    // Use the node type slug as component name
    super(node_type.slug)

    this.data = {
      render: 'vue',
      component: CustomNode,
      props: {
        title: node_type.name,
        description: node_type.description,
      }
    }
  }

  builder(node) {
    const next = new Rete.Output("next", "Noeud suivant", Socket.trigger, false)//todo: comon ?
    const previous = new Rete.Input("previous", "", Socket.triggered, true)
    const video = new AssetControl("integrated", null, ['integrated_video'], true)
    const startTime = new TextControl("startTime", "Début (ms)", false)
    const endTime = new TextControl("endTime", "Fin (ms)", false)

    const frame = new SelectControl("frame", "Cadre", { storeFn: (store) => {
      const entityList = store.state.Entity.list

      if (!entityList)
        return []

      return entityList.filter((entity) => {
        return entity.type.slug == 'frame'
      })
    }, }, false)

    return node
      .addInput(previous)
      .addControl(video)
      .addControl(frame)
      .addControl(startTime)
      .addControl(endTime)
      .addOutput(next)
  }
}
