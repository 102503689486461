import gql from "graphql-tag"

const GC_GET_COURSE_LIST = gql`
query GetCourses {
    course {
        name
        description
        id
    }
}
`

const GC_GET_COURSE = gql`
	query GetCourse($id: uuid!) {
		course_by_pk(id: $id) {
			id
			type
			name
			modules(order_by: {order: asc}) {
				id
				order
				name
				title
				sequences(order_by: {order: asc}) {
					id
					name
					order
					title
					module_scenario_limit
					scenarios(order_by: {order: asc}) {
						order
						scenario{
							id
							name
							title
							type: scenarioType {
								slug
							}
						}
					}
					medias(order_by: {order: asc}) {
						order
						media{
							id
							name
							title
							type: mediaType {
								slug
							}
						}
					}
				}
				scenarios {
					scenario{
						name
						id
					}
				}
			}
		}
	}
`

const GC_ADD_COURSE_ONE = gql`
mutation AddCourse($course: course_insert_input!) {
  insert_course_one(object: $course) {
    id
  }
}`

const GC_UPDATE_COURSE = gql`
mutation UpdateCourse(
	$id: uuid!,
	$name: String!,
	$description: String!,
	$type: uuid!,
	$module_ids: [uuid!]!,
	$modules: [module_insert_input!]!,
	$delete_module_scenarios_condition: module_scenario_bool_exp!,
	$module_scenario_list: [module_scenario_insert_input!]!,
	$delete_sequence_condition: sequence_bool_exp!,
	$module_sequence_list: [sequence_insert_input!]!,
	$delete_sequence_scenarios_condition: sequence_scenario_bool_exp!,
	$sequence_scenario_list: [sequence_scenario_insert_input!]!,
	$delete_sequence_medias_condition: sequence_media_bool_exp!,
	$sequence_media_list: [sequence_media_insert_input!]!,
) {
	## Update course base data
	update_course_by_pk(
		pk_columns: { 
			id: $id
		},
	  _set: {
	    name: $name,
	    description: $description,
	    type: $type,
	  }
	) {
    id
  }

  ## Delete old module
  delete_module(where: {
    id: {_nin: $module_ids},
    course_id: {_eq: $id}
  }) {
    affected_rows
  }
  
  ## Insert new modules or update base data for existing ones
  insert_module(
  	objects: $modules,
  	on_conflict: {
  		constraint: module_pkey,
  		update_columns: [name, title, order, course_id]
  	}
  ) {
    affected_rows
  }

  ### Update existing module associations ###

  ## Delete/Update module associated scenarios
  delete_module_scenario(where: $delete_module_scenarios_condition) {
    affected_rows
  }
  insert_module_scenario(
  	objects: $module_scenario_list,
  	on_conflict: {
  		constraint: module_scenario_pkey,
  		update_columns: []
  	}
  ) {
    affected_rows
  }

  ### Add/Update sequences ###

  ## Delete old sequences
  delete_sequence(where: $delete_sequence_condition) {
  	affected_rows
  }

  ## Insert new sequences or update base data for existing ones
  insert_sequence(
  	objects: $module_sequence_list,
  	on_conflict: {
  		constraint: sequence_pkey,
  		update_columns: [name, title, module_scenario_limit, order, module_id]
  	}
  ) {
    affected_rows
  }

  ### Update existing sequences associations ###

  ## Delete old sequences scenarios associations
  delete_sequence_scenario(where: $delete_sequence_scenarios_condition) {
    affected_rows
  }

  ## Add new sequence scenarios associations or update base data for existing ones
  insert_sequence_scenario(
  	objects: $sequence_scenario_list,
  	on_conflict: {
  		constraint: sequence_scenario_pkey,
  		update_columns: [order]
  	}
  ) {
    affected_rows
  }

  ## Delete old sequences medias associations
  delete_sequence_media(where: $delete_sequence_medias_condition) {
    affected_rows
  }

  ## Add new sequence medias associations or update base data for existing ones
  insert_sequence_media(
  	objects: $sequence_media_list,
  	on_conflict: {
  		constraint: sequence_media_pkey,
  		update_columns: [order]
  	}
  ) {
    affected_rows
  }
}
`

const GC_GET_COURSE_TYPES = gql`
	query GetCourseTypes {
		course_type {
			id
			name
			slug
		}
	}
`

const SEARCH_SCENARIO = gql`
	query SearchScenario($name: String, $slugs: [String!]!) {
		scenario(where: { name: { _ilike: $name }, scenarioType: { slug: { _in: $slugs } } }
				limit: 10) {
			id
			name
			scenario_type_id
		}
	}
`

const GC_DELETE_COURSE = gql`
mutation DeleteCourse($id: uuid!) {
	delete_course_by_pk(id: $id) {
		id
		name
	}
}
`

const GC_CHECK_NAME = gql`
query CheckName($name: String) {
  course(where: {name: {_eq: $name}}) {
    id
  }
}
`

export { GC_GET_COURSE_LIST, GC_ADD_COURSE_ONE, GC_GET_COURSE_TYPES, GC_GET_COURSE, SEARCH_SCENARIO, GC_DELETE_COURSE, GC_UPDATE_COURSE, GC_CHECK_NAME }
