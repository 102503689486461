<template>
    <div class="user-detail mt-8 md:mt-0">
        <div class="buttons flex flex-row justify-around md:justify-between">
            <button type="button" class="twn-button text-xs" @click="goBack">Retour</button>
            <div v-if="user && user.id">
                <button type="button" class="twn-button text-xs danger mr-4" :disabled="hasPendingStoreRequest" @click="showDeleteModal">Supprimer le profil</button>
                <button type="button" class="twn-button text-xs" :disabled="hasPendingStoreRequest" @click="save">Enregistrer le profil</button>
            </div>
            <button type="submit" class="twn-button text-sm" :disabled="hasPendingStoreRequest" @click="save" v-else>Ajouter et envoyer l'email de bienvenue</button>
        </div>
        <div class="flex flex-col" v-if="user">
            <div class="twn-card user-card my-10">
                <div class="flex justify-between items-center">
                    <div class="uppercase font-principal-bold text-sm" v-if="!user.id">Ajouter un profil</div>
                    <div class="uppercase font-principal-bold text-sm" v-else>éditer le profil</div>
                    <button v-if="user.id && !isUserValidated" type="button" class="twn-button text-xs" @click="sendValidationCode" :disabled="isUserValidated">Envoyer un code d'inscription</button>
                    <p class="text-green-700" v-if="user.cognito_id && user.last_log && user.last_log.length <= 0">Email envoyé</p>
                    <p class="text-green-700" v-else-if="user.cognito_id">Validé</p>
                    <p class="text-red-700" v-else>Inactif</p>
                </div>
                <hr>
                <form class="flex flex-row flex-wrap justify-around" @submit="save">
                    <div class="left">
                        <div class="input-group mt-0 md:mt-10">
                            <label for="email">Adresse e-mail</label>
                            <b-form-input v-model="user.email" type="email" id="email" :state="emailValidation"
                            :readonly="hasEmail" :disabled="hasEmail"></b-form-input>
                        </div>
                        <div class="input-group">
                            <label for="first_name">Prénom</label>
                            <b-form-input v-model="user.first_name" type="text" id="first_name"></b-form-input>
                        </div>
                        <div class="input-group">
                            <label for="name">Nom</label>
                            <b-form-input v-model="user.name" type="text" id="name" :state="nameValidation"></b-form-input>
                        </div>
                        <div class="input-group" v-if="(user.role && user.role !== 'superadmin') || isAdmin">
                            <label for="role">Rôle</label>
                            <v-select
                                v-model="user.role"
                                id="role"
                                :options="roleList"
                                label="name"
                                class="twn-select"
                                :reduce="role => role.slug"
                                :clearable="false"
                            >
                            </v-select>
                        </div>
                        <div class="input-group">
                            <label for="gender">Genre</label>
                            <v-select
                                v-model="user.gender"
                                id="gender"
                                :options="userFieldsValues.gender"
                                class="twn-select"
                                :reduce="data => data.value"
                                :clearable="false"
                            >
                            </v-select>
                        </div>
                        <div class="input-group">
                            <label for="age">Age</label>
                            <v-select
                                v-model="user.age"
                                id="age"
                                :options="userFieldsValues.age"
                                class="twn-select"
                                :reduce="data => data.value"
                                :clearable="false"
                            >
                            </v-select>
                        </div>
                        <div class="input-group">
                            <b-form-checkbox v-model="user.is_disabled_worker" id="is_disabled_worker">
                                Travailleur(se) handicapé(e)
                            </b-form-checkbox>
                        </div>
                        <div class="input-group">
                            <label for="level_studies">Niveau d'éducation</label>
                            <v-select
                                v-model="user.level_studies"
                                id="level_studies"
                                :options="userFieldsValues.level_studies"
                                class="twn-select"
                                :reduce="data => data.value"
                                :clearable="false"
                            >
                            </v-select>
                        </div>
                        <div class="input-group">
                            <label for="actual_position">Poste</label>
                            <b-form-input v-model="user.actual_position" type="text" id="actual_position"></b-form-input>
                        </div>
                    </div>
                    <div class="right">
                        <div class="input-group">
                            <label for="actual_working_time">Temps de travail</label>
                            <b-form-input v-model="user.actual_working_time" type="text" id="actual_working_time"></b-form-input>
                        </div>
                        <div class="input-group">
                            <b-form-checkbox v-model="user.rise_working_time" id="rise_working_time">
                                Souhaite un temps augmenté de travail
                            </b-form-checkbox>
                        </div>
                        <div class="input-group">
                            <label for="contract">Contrat</label>
                            <v-select
                                v-model="user.contract"
                                id="contract"
                                :options="userFieldsValues.contract"
                                class="twn-select"
                                :reduce="data => data.value"
                                :clearable="false"
                            >
                            </v-select>
                        </div>
                        <div class="input-group">
                            <label for="experiences">Années d'expériences</label>
                            <v-select
                                v-model="user.experiences"
                                id="experiences"
                                :options="userFieldsValues.experiences"
                                class="twn-select"
                                :reduce="data => data.value"
                                :clearable="false"
                            >
                            </v-select>
                        </div>
                        <div class="input-group">
                            <b-form-checkbox v-model="user.has_professional_courses" id="has_professional_courses">
                                A déjà suivi une formation professionnelles
                            </b-form-checkbox>
                        </div>
                        <div class="input-group">
                            <label for="professional_courses">Formation(s) suivie(s)</label>
                            <b-form-input v-model="user.professional_courses" type="text" id="professional_courses"></b-form-input>
                        </div>
                        <div class="input-group">
                            <b-form-checkbox v-model="user.has_distance_learning" id="has_distance_learning">
                                A déjà suivi une formation en elearning
                            </b-form-checkbox>
                        </div>
                        <div class="input-group">
                            <label for="expectations">Attente(s)</label>
                            <b-form-input v-model="user.expectations" type="text" id="expectations"></b-form-input>
                        </div>
                        <div class="input-group">
                            <label for="additionnal_needs">Autres besoins</label>
                            <b-form-input v-model="user.additionnal_needs" type="text" id="additionnal_needs"></b-form-input>
                        </div>
                    </div>
                </form>
            </div>
            <div v-if="user && user.promotions">
                <b-table
                    :items="user.promotions"
                    :fields="promotionArrFields"
                >
                </b-table>
            </div>

            <!-- <div class="my-10" v-if="getUser.id">
                <div class="uppercase font-principal-bold text-sm">Statistiques utilisateur</div>
                <UserDashboard :id="getUser.id" />
            </div> -->
        </div>
        <b-modal ref="delete-modal-detail-user" class="bootstrap" centered hide-footer id="delete-modal-detail-user" hide-header>
            <div class="d-block text-center my-6 uppercase font-semibold">
                <h3>Confirmer la suppression</h3>
            </div>
            <div class="flex flex-row justify-evenly items-center">
                <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal-detail-user')">Retour</button>
                <button type="button" class="mt-4 twn-button danger" @click="deleteItem">Supprimer</button>
            </div>
        </b-modal>
    </div>
</template>
<script>

import { mapState } from 'vuex';

import dispatchStoreRequest from "@/mixins/dispatchStoreRequest"

// import UserDashboard from '@/components/dashboard/User'

import userFieldsValues from "@/constants/userFieldsValues"

export default {
    name: 'UserDetail',
    mixins: [ dispatchStoreRequest ],
    props: {
        itemID: {
            type: String,
            required: false
        },
        previousPage: {
            type: Number,
            required: false
        }
    },
    // components:{
    //     UserDashboard
    // },
    data() {
        return {
            user: null,
            isUserValidated: true,
            userFieldsValues,
            hasEmail: false,
            promotionArrFields:[
				{
					key: "index",
					label: "",
					sortable: false
				},
				{
					key: "title",
					label: "Promotions associées",
					sortable: false
                },
				{
					key: "start_date",
					label: "Date de début",
					sortable: false
                },
				{
					key: "end_date",
					label: "Date de fin",
					sortable: false
				},
			],
        }
    },
    computed: {
        ...mapState('Auth', [ 'userInfo' ]),
        ...mapState('Utils', [ 'organisationNameList', 'statusNameList', 'userList' ]),
        isAdmin() {
            return (this.userInfo.role == 'superadmin')
        },
        nameValidation(){
            return this.user.name !== undefined && this.user.name !== null && this.user.name !== '';
        },
        emailValidation(){
            const valid = (this.user.email !== undefined && this.user.email !== '')

            // Check if email exist when creating an user
            if (!this.user || !this.user.id) {
                return valid && !this.userList.find((user) => {
                    return (user.email == this.user.email)
                })
            }

            return valid
        },
        roleList() {
            let list = [
                {slug:"user", name:"Utilisateur"},
            ]

            if (this.isAdmin) {
                list.push({slug:"superadmin", name:"SuperAdmin"})
            }

            return list
        },
    },
    watch: {
        itemID: {
            async handler(id) {
                // Load current user data, if needed
                if (id) {
                    await this.loadData()

                    // todo: common/cleaner system
                    document.title = this.user.name + ' ' + this.user.first_name
                } else {
                    // Get default object without cache
                    this.user = await this.dispatchStoreRequest('Users/getDefault', null, true)
                }
            },
            immediate: true
        }
    },
    methods: {
        async loadData() {
            // Get user from store
            // todo: handle invalid uuid response
            await this.dispatchStoreRequest("Users/getByID", this.itemID, true)

            // Create a deep local copy of the store data (todo: really needed ?)
            this.user = JSON.parse(JSON.stringify(this.$store.state.Users.items[this.itemID]))

            // Not a computed to be able to fill the email field without a soft-lock, so we update this value only on remote user data update
            this.hasEmail = !!this.user.email
        },
        goBack() {
            this.$router.push({
                name: 'users-list',
                params: {
                    previousPage: this.previousPage
                }
            })
        },
        showDeleteModal() {
            this.$refs['delete-modal-detail-user'].show();
        },
        async deleteItem() {
            if (this.hasPendingStoreRequest)
                return

            await this.dispatchStoreRequest('Users/delete', this.user)

            this.$refs['delete-modal-detail-user'].hide()
            this.$router.push({ name: 'users-list' })
        },
        async save(e) {
            e.preventDefault()
            
            if (!this.nameValidation || !this.emailValidation || this.hasPendingStoreRequest)
                return

            // Cleanup data
            delete this.user.cognito_id
            delete this.user.last_log
            delete this.user.content_logs

            const response = await this.dispatchStoreRequest('Users/save', {
                user: this.user,
            })

            if (response.error) {
                this.$bvToast.toast('Erreur de création de l\'utilisateur.', { title: `Erreur` })
                return
            } else if (response.id) {
                this.$router.push({
                    name: 'users-edit',
                    params: {
                        itemID: response.id,
                    }
                })
            } else {
                // Refresh user data
                await this.loadData()
            }

            this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })
        },
        async sendValidationCode() {
            if (this.isUserValidated)
                return

            this.isUserValidated = true

            try {
                await this.$store.dispatch('Auth/sendValidationToken', this.user.email)//todo: email check ? real email ?
                alert('Code envoyer à ' + this.user.email)
            } catch (error) {
                this.isUserValidated = false
            }
        },
    },
}
</script>
<style lang="scss">
.user-card{
    > div{
        @apply my-5;
    }
    .left, .right{
        @apply w-full;
        > div {
            @apply flex flex-row justify-around items-center my-5 flex-no-wrap;
            > label {
                @apply text-right mr-5 w-1/3 text-xs;
            }
            > input{
                &:not(.is-invalid){
                    @apply border-line-top;
                }
                @apply w-2/3 flex-auto;
                border-radius: 0.275rem !important;
            }
            .twn-select {
                @apply w-2/3;
                .vs__dropdown-toggle {
                    @apply border-line-top;
                }
                &.is-invalid{
                    .vs__dropdown-toggle {
                        border-color: #dc3545;
                    }
                }
            }
        }
        @screen xs {
            > div > label {
                @apply text-sm;
            }
        }
    }
    @screen md {
        .left, .right {
            > div {
                @apply my-10;
            }
            @apply w-1/2;
            .button-holder{
                @apply flex flex justify-end items-end;
            }
        }
    }
    hr{
        box-sizing: border-box;
        border: 1px solid;
        @apply border-line-top rounded-lg;
    }
}
</style>