import Rete from "rete";

import VueRenderPlugin from "rete-vue-render-plugin";
import ConnectionPlugin from "rete-connection-plugin";
import AreaPlugin from "rete-area-plugin";
import DockPlugin from 'rete-dock-plugin';

// Common
import { ColorComponent } from "./components/ColorComponent";// todo: cleanup
import { AddComponent } from "./components/AddComponent";// todo: cleanup
import { SceneComponent } from "./components/SceneComponent";
import { VideoComponent } from "./components/VideoComponent";
import { PDFComponent } from "./components/PDFComponent";
import { GameComponent } from "./components/GameComponent";

// Admin
import { SimpleProposalComponent } from "./components/SimpleProposalComponent";
import { InteractiveComponent } from "./components/InteractiveComponent";

// RS
import { VideoPartComponent } from "./components/VideoPartComponent";
import { ModalComponent } from "./components/ModalComponent";
import { SubtitlesComponent } from "./components/SubtitlesComponent";
import { SMSComponent } from "./components/SMSComponent";
import { AudioMessageComponent } from "./components/AudioMessageComponent";
import { VideoCallComponent } from "./components/VideoCallComponent";
import { ActionsComponent } from "./components/ActionsComponent";
import { ExplorationComponent } from "./components/ExplorationComponent";

// EDIAD
import { EmbedComponent } from "./components/EmbedComponent";


import { TextControl } from "./controls/TextControl.js";// todo: cleanup

import * as Socket from "./sockets";// todo: cleanup

import data from "./data.json";// todo: cleanup

// Rete component class associated to node type slug
const NODE_TYPE_SLUG_CLASS = {
  // Common
  scene: SceneComponent,
  media: VideoComponent,
  pdf: PDFComponent,
  game: GameComponent,
  // Admin
  simple_proposal: SimpleProposalComponent,
  interactive: InteractiveComponent,
  // RS
  video: VideoComponent,
  video_part: VideoPartComponent,
  modal: ModalComponent,
  subtitles: SubtitlesComponent,
  sms: SMSComponent,
  audio_message: AudioMessageComponent,
  video_call: VideoCallComponent,
  actions: ActionsComponent,
  exploration: ExplorationComponent,
  // EDIAD
  embed: EmbedComponent,
}

export default async function(container, dock_container, node_types = []) {
  // Init Rete in the specified container and setup plugins
  var editor = new Rete.NodeEditor("ScenarioEditor@1.0.0", container);

  editor.use(ConnectionPlugin);
  editor.use(VueRenderPlugin);
  
  editor.use(AreaPlugin, {
    scaleExtent: { min: 0.25, max: 1 }
  });

  editor.use(DockPlugin, {
    container: dock_container,
    itemClass: 'item',
    plugins: [VueRenderPlugin],
  });

  // Init and register node type components
  const components = node_types.map((type) => {
    if (!type || !type.slug || !NODE_TYPE_SLUG_CLASS[type.slug])
      return null

    return (new NODE_TYPE_SLUG_CLASS[type.slug](type))
  }).filter((component) => (component !== null))

  components.map(c => {
    editor.register(c);
  });

  // Init editor area
  AreaPlugin.zoomAt(editor);

  return editor
}
