import Rete from "rete"

import CustomNode from './CustomNode.vue'
import * as Socket from "../sockets"

import { TextControl } from "../controls/TextControl.js"

export class ModalComponent extends Rete.Component {
  constructor(node_type) {
    // Use the node type slug as component name
    super(node_type.slug)

    this.data = {
      render: 'vue',
      component: CustomNode,
      props: {
        title: node_type.name,
        description: node_type.description,
      }
    }
  }

  builder(node) {
    const next = new Rete.Output("next", "Noeud suivant", Socket.trigger, false)//todo: comon ?
    const previous = new Rete.Input("previous", "", Socket.triggered, true)
    const title = new TextControl("title", "Titre", false)
    const text = new TextControl("text", "Texte", false, 4)

    node.dynamicOutputType = 'button'
    node.dynamicOutputLimit = 4
    
    return node
      .addInput(previous)
      .addControl(title)
      .addControl(text)
      .addOutput(next)
  }
}
