<template>
    <div class="flex p-2">
      <div class="rounded-full w-5 h-5 mr-2 bg-blue-200 flex-none flex items-center justify-center">
        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.93144 6.00637L2.36328 2.88806M2.36328 2.88806L6.03775 1.22729M2.36328 2.88806C4.16517 2.95839 8.1009 3.83377 9.42871 6.77269" stroke="white" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </div>
      
      <div class="mx-2 text-xs min-w-0">
        <div class="font-bold">{{ replyTo.user.first_name }} {{ replyTo.user.name }}</div>
        <div class="overflow-ellipsis overflow-hidden whitespace-nowrap">{{ replyTo.content }}</div>
      </div>

      <div class="rounded-full w-4 h-4 bg-red-400 cursor-pointer flex-none flex items-center justify-center self-center ml-auto" @click="setReplyTo(null)">
        <svg width="8" height="8" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1.61536L1.00028 8.61508" stroke="white" stroke-width="2" stroke-linecap="round"/>
          <path d="M1 1.61536L7.99972 8.61508" stroke="white" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    replyTo: {
      type: Object,
      default: () => {}
    },
  },

  methods: {
    setReplyTo(message) {
      this.$store.commit('Conversation/SET_STATE', {
        replyTo : message
      })
    }
  }
}
</script>
