import Rete from "rete";
import VueItemChooserControl from "./ItemChooserControl.vue";

export class ItemChooserControl extends Rete.Control {
  constructor(key, label, data_source, clearable = false) {
    super(key);
    this.component = VueItemChooserControl;
    this.props = { ikey: key, label, data_source, clearable };
  }
}
