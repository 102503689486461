<template>
	<div
		class="w-full question flex flex-row relative justify-center items-center"
		:class="noStyle ? 'no-style' : ''"
	>
		<div class="handle self-stretch w-10 flex justify-center items-center cursor-move">
			<div class="rotate-icon">
				<b-icon-justify></b-icon-justify>
			</div>
		</div>
		<div class="flex items-center flex-1">
			<div
				class="handle cursor-pointer number bg-principal-selected w-10 self-stretch flex justify-center items-center text-xs"
			>
				<div>{{ number + 1 }}</div>
			</div>

			<!-- Textual question -->
			<div class="content p-10 w-full" v-if="!imageType">
				<!-- Media -->
				<div v-if="options && options.hasMedia">
					<button 
						type="button"
						class="twn-button text-sm mb-4 mr-4"
						@click="openMediaChoice"
					>
						Associer un média à la question
					</button>
					<span class="mr-4" v-if="media">{{ media.name }}</span>
					<span v-if="media" @click="$emit('remove-media')" class="cursor-pointer">x</span>
				</div>

				<!-- Text -->
				<div class="input-field w-full">
					<b-form-textarea
						v-model="questionLabel"
						max-rows="6"
						type="text"
						:placeholder="
							options && options.questionPlaceholder ? options.questionPlaceholder : 'Question'
						"
					></b-form-textarea>
				</div>
				<div class="answers w-full flex flex-row justify-center items-center mt-5">
					<!-- Answer text -->
					<div class="box-answers w-full" v-bind:class="[answers.length == 0 ? 'w-0' : '']">
						<draggable v-model="answers" handle=".answer-handle">
							<div
								v-for="(answer, i) in answers"
								:key="i"
								class="w-full flex flex-row justify-center items-center"
							>
								<!-- Number -->
								<div class="answer-handle cursor-pointer w-10 flex items-center justify-center">
									<div class="rotate-icon">
										<b-icon-justify></b-icon-justify>
									</div>
								</div>
								<div class="number answer-handle w-10 text-center self-stretch p-2 cursor-pointer">
									<div>{{ i + 1 }}</div>
								</div>
								<!-- Input/Text -->
								<div class="input-field w-full p-2">
									<!-- <span v-if="options && options.fixedAnswers">{{answer.text}}</span> -->
									<b-form-textarea
										max-rows="6"
										class="w-full border-none"
										@input="updateAnswerText($event, i)"
										type="text"
										:placeholder="
											options && options.answerPlaceholder
												? options.answerPlaceholder
												: 'Réponse'
										"
										:value="answer.text"
									/>
								</div>
								<div
									class="bg-principal-selected cursor-pointer self-stretch flex items-center text-white justify-center w-10"
									@click="removeAnswer(i)"
									v-if="!options || (options && !options.fixedAnswers)"
								>
									<b-icon-x></b-icon-x>
								</div>
							</div>
						</draggable>
					</div>

					<!-- Right/Wrong answer switch -->
					<div class="check-answers self-stretch flex flex-col justify-around items-center">
						<div
							v-for="(answer, i) in answers"
							:key="i"
							class="w-full mx-3 flex flex-row justify-center items-center"
						>
							<label class="switch">
								<input type="checkbox" :checked="answer.is_correct" @input="checkAnswer(i)" />
								<span class="slider round"></span>
							</label>
						</div>
					</div>

					<!-- New answer button -->
					<div
						v-if="!options || (options && !options.fixedAnswers)"
						v-bind:class="[answers.length == 0 ? 'w-full' : '']"
					>
						<div
							@click="addDefaultAnswer(number)"
							class="plus m-auto rounded-full w-10 h-10 flex justify-center items-center cursor-pointer"
						>
							+
						</div>
					</div>
				</div>
				<div class="w-full input-group mt-10" v-if="!options || (options && !options.noCorrection)">
					<b-form-textarea
						v-model="correctionText"
						class="w-full"
						max-rows="6"
						:id="'correction_' + number"
						:placeholder="
							options && options.correctionPlaceholder ? options.correctionPlaceholder : 'Correction'
						"
					></b-form-textarea>
				</div>
			</div>
			<div
				v-else
				class="w-full m-5 image-content"
				@click="openMediaChoice"
				@dragover="allowDrop"
				@drop.prevent.stop="dropImageInQuestion($event)"
			>
				<div class="drop-zone p-10 w-full h-full border-dashed border-2" v-if="!media">
					<div class="m-auto w-1/3 break-words text-center text-xs">
						Ajouter une image depuis la bibliothèque de média
					</div>
				</div>
				<div v-else class="image m-auto w-1/5 h-2/3">
					<img v-if="media.thumbnail_url" :src="media.thumbnail_url" alt="" class="" />
					<img v-else src="@/assets/images/thumbnails/VIDEO_THUMBNAIL.svg" />
				</div>
			</div>
			<div></div>
		</div>
		<div
			@click="removeQuestion"
			class="cursor-pointer deleteQuestion self-stretch w-10 flex justify-center items-center text-white"
		>
			<b-icon-x></b-icon-x>
		</div>
	</div>
</template>
<script>
import Draggable from "vuedraggable"
import dispatchStoreRequest from "@/mixins/dispatchStoreRequest"

export default {
	name: "Question",
	components: {
		Draggable
	},
	mixins: [dispatchStoreRequest],
	props: {
		number: {
			type: Number,
			required: true,
			default: null
		},
		options: {
			type: Object,
			required: false
		},
		question: {
			type: Object,
			required: true,
			default: null
		},
		imageType: {
			type: Boolean,
			required: false,
			default: false
		},
		mediaLoaded: {
			type: Boolean,
			required: false
		},
		noStyle: {
			Type: Boolean,
			required: false
		}
	},
	data() {
		return {
			media: null
		}
	},
	computed: {
		questionLabel: {
			get() {
				return this.question.text
			},
			set(value) {
				this.$emit("update-question", this.number, {
					text: value
				})
			}
		},
		correctionText: {
			get() {
				return this.question.correction
			},
			set(value) {
				this.$emit("update-question", this.number, {
					correction: value
				})
			}
		},
		answers: {
			get() {
				return this.question.answers
			},
			set(value) {
				this.$emit("update-question", this.number, {
					answers: value
				})
			}
		},
	},
	methods: {
		updateAnswerText(input, i) {
			let newAnswers = [...this.answers]
			newAnswers[i].text = input
			this.answers = newAnswers
		},
		removeQuestion() {
			this.$emit("delete-question", this.number)
		},
		addDefaultAnswer() {
			let newAnswers = [...this.answers]
			newAnswers.push({
				text: "",
				is_correct: false
			})
			this.$emit("update-question", this.number, {
				answers: newAnswers
			})
		},
		checkAnswer(i) {
			let correct = true
			let newAnswers = [...this.answers]
			if (newAnswers[i].is_correct) {
				correct = false
			}
			newAnswers[i].is_correct = correct
			this.answers = newAnswers
		},
		removeAnswer(i) {
			let newAnswers = [...this.answers]
			newAnswers.splice(i, 1)
			this.answers = newAnswers
		},
		openMediaChoice() {
			this.$emit("open-panel")
		},
		async dropImageInQuestion(e) {
			if (e.dataTransfer) {
				const assetJSON = e.dataTransfer.getData("application/json")
				const asset = assetJSON ? JSON.parse(assetJSON) : null

				this.media = await this.dispatchStoreRequest("Assets/getAssetFromID", asset.id, true)

				this.$emit("update-question", this.number, {
					media_id: asset.id
				})
			}
		},
		allowDrop: function(event) {
			event.preventDefault()
		}
	},
	watch: {
		async question(newVal, oldVal) {
			if (newVal.media_id && (!oldVal.media_id || oldVal.media_id !== newVal.media_id)) {
				this.media = await this.dispatchStoreRequest("Assets/getAssetFromID", newVal.media_id, true)
			}
			if (!newVal.media_id) {
				this.media = null
			}
		},
		async mediaLoaded() {
			if (this.question.media_id) {
				this.media = await this.dispatchStoreRequest("Assets/getAssetFromID", this.question.media_id, true)
			}
		}
	}
}
</script>
<style lang="scss">
.plus {
	background-color: rgba(225, 225, 225, 0.15);
}
.question:not(.no-style) {
	box-shadow: $cardShadow;
	border-radius: 10px;
	overflow: hidden;
	&.removeState {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	color: $textLight;
	.number {
		background-color: rgba(220, 151, 153, 0.1);
	}

	.rotate-icon {
		transform: rotate(90deg);
	}

	.image-content {
		.image {
			img {
				max-height: 150px;
			}
		}
	}

	.deleteQuestion {
		@apply bg-principal-selected;
		// border-radius: 0 10px 10px 0;
	}

	.answers {
		.box-answers {
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
		}
		.remove-state {
			> :first-child {
				@apply bg-principal-selected text-white;
			}
			> :last-child {
				@apply border-principal-selected;
			}
		}
		.input-field {
			textarea {
				height: 1.4rem;
			}
			border: solid 0.3px #e1e1e1;
			border-left: none;
		}

		/* The switch - the box around the slider */
		.switch {
			position: relative;
			display: inline-block;
			width: 50px;
			height: 24px;
			margin-bottom: 0;
		}

		/* Hide default HTML checkbox */
		.switch input {
			opacity: 0;
			width: 0;
			height: 0;
		}

		/* The slider */
		.slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #ccc;
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}

		.slider:before {
			position: absolute;
			content: "";
			height: 16px;
			width: 16px;
			left: 4px;
			bottom: 4px;
			background-color: white;
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}

		input:checked + .slider {
			@apply bg-principal-selected;
		}

		input:checked + .slider:before {
			-webkit-transform: translateX(26px);
			-ms-transform: translateX(26px);
			transform: translateX(26px);
		}

		/* Rounded sliders */
		.slider.round {
			border-radius: 24px;
		}

		.slider.round:before {
			border-radius: 50%;
		}
	}
}
</style>
