<template>
    <div id="conversationMessageList" class="overflow-auto flex flex-col p-3">
        <div v-if="messages.length > 0">
            <ConversationMessage
                v-for="(message, index) in messages"
                :key="message.id"
                :message="message"
                :previousMessageUserId="((messages[index - 1] || {}).user || {}).id"
                :nextMessageUserId="((messages[index + 1] || {}).user || {}).id"
            />
        </div>
        <div class="text-xs text-green-primary p-3 text-center" v-else>
            Il n'y a aucun message dans cette conversation.
        </div>
    </div>
</template>

<script>
import ConversationMessage from "@/components/conversation/ConversationMessage"

export default {
    name: 'ConversationMessageList',

    components: {
        ConversationMessage,
    },
    
    props: {
        messages: {
            type: Array,
            default: () => [],
        },
    },
    
    mounted() {
        const element = document.querySelector('#conversationMessageList')
        element.scrollTop = element.scrollHeight
    },
}
</script>

<style lang="scss">
</style>