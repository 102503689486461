<template>
  <div class="c-entities-panel">
    <div class="search flex items-center pr-2">
      <b-icon-search class="h2 my-3 mx-2"></b-icon-search>
      <input
        v-model="searchStr"
        type="text"
        id="search"
        class="form-control w-4/5"
        placeholder="Rechercher"
      />
    </div>

    <div class="c-entities-panel__list pr-4 pl-1 py-2">
      <transition-group name="fade-x" tag="div">
        <div
          v-for="entity in filteredEntities"
          :key="entity.id"
          :class="[
            'px-4 py-2 flex items-center justify-between hover:text-principal-selected rounded-lg mb-2',
            'duration-200 transform hover:shadow-twn hover:font-bold transition-all cursor-pointer',
          ]"
          @click="onEntityClick(entity)"
        >
          <div class="flex items-center">
            <div class="text-sm">{{ (entity.identifier ? entity.identifier + ' - ' : '') }}{{ entity.name }}</div>
          </div>

          <div class="flex">
            <div
              class="transform hover:scale-150 duration-200"
              @click.stop="onEditClick(entity)"
            >
              <img src="@/assets/images/EDIT.svg" class="h-5" />
            </div>
          </div>
        </div>
      </transition-group>
    </div>

    <hr class="my-4" />

    <button v-if="!selectedEntity" class="twn-button" type="button" @click="onNewClick">
      Ajouter une entité
    </button>

    <div v-if="selectedEntity" class="c-entities-panel__add-section px-2">
      <div>
        <label>Identifiant</label>
        <b-form-input v-model="selectedEntity.identifier" type="text" />
      </div>

      <div>
        <label>Nom</label>
        <b-form-input v-model="selectedEntity.name" type="text" />
      </div>

      <div class="my-4">
        <label>Description</label>
        <b-form-textarea v-model="selectedEntity.description" type="text" />
      </div>

      <div class="flex justify-between">
        <button class="twn-button" type="button" :disabled="loading" @click="onCancelClick">Annuler</button>
        <button v-if="selectedEntity && selectedEntity.id" class="twn-button" type="button" :disabled="loading" @click="onSaveClick">Enregistrer</button>
        <button v-else class="twn-button" type="button" :disabled="loading" @click="onSaveClick">Créer</button>
      </div>
    </div>

    <!-- Modals -->
    <b-modal
      ref="save-modal"
      class="bootstrap"
      centered
      hide-footer
      id="save-modal"
      hide-header
    >
      <div class="d-block text-center mt-6 mb-2 font-semibold">
        <h3 class="uppercase">Confirmer la modification</h3>
      </div>
      <div class="d-block text-center mb-4">
        <p>Cette entité a déjà été associée à un identifiant Unity voulez-vous vraiment la modifier ?</p>
      </div>
      <div class="flex flex-row justify-evenly items-center">
        <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('save-modal')">
          Retour
        </button>
        <button type="button" class="mt-4 twn-button" @click="onSaveConfirmClick">Modifier</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'

  export default {
    mixins: [ dispatchStoreRequest ],
    props: {
      entities: {
        type: Array,
        required: true,
        default: () => []
      },
      entityType: {
        type: Object,
        required: false,
        default: () => null
      },
    },
    data: () => ({
      searchStr: null,
      selectedEntity: null,
      loading: false,
    }),
    computed: {
      filteredEntities() {
        if (!this.entityType)
          return []

        return this.entities.filter((entity) => {
          if (entity.type.id != this.entityType.id)
            return false

          if (!this.searchStr)
            return true

          return entity.name
          .toLowerCase()
          .includes(this.searchStr.toLowerCase());
        });
      },
    },
    methods: {
      onEntityClick(entity) {
        this.$emit('select-item', entity)
      },
      onEditClick(entity) {
        if (this.loading)
          return

        this.selectedEntity = {
          id: entity.id,
          identifier: entity.identifier,
          name: entity.name,
          description: entity.description,
          slug: entity.slug,
          default: entity.default,
          data: entity.data,
          entity_type_id: entity.type.id,
        }
      },
      onNewClick() {
        if (this.loading || !this.entityType)
          return

        this.selectedEntity = {
          identifier: '',
          name: '',
          description: '',
          slug: '',
          default: false,
          data: {},
          entity_type_id: this.entityType.id,
        }
      },
      onSaveClick() {
        if (this.selectedEntity.slug != '') {
          this.$refs['save-modal'].show()
        } else {
          this.onSaveConfirmClick()
        }
      },
      async onSaveConfirmClick() {
        if (this.loading || !this.selectedEntity)
          return

        this.loading = true

        this.$refs['save-modal'].hide()

        // Save entity
        await this.dispatchStoreRequest('Entity/save', this.selectedEntity)

        // Reload list todo:improve ?
        await this.dispatchStoreRequest('Entity/getList', null, true)

        this.selectedEntity = null
        this.loading = false
      },
      onCancelClick() {
        if (this.loading)
          return

        this.selectedEntity = null
      },
    },
  }
</script>

<style lang="scss" scoped>
.c-entities-panel {
  @apply flex flex-col;

  &__list {
    height: 30vh;
  }
  
  &__add-section {
    // height: 40vh;
  }

  &__list,
  &__add-section {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>